import React from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import cn from 'classnames';

import { RoleConsumer } from '@utils/Roles';

import { Button, ButtonIntent, ButtonSize } from '@ui/_deprecated/Button';
import { insertText } from '@utils/documentExecCommand';
import { EmojiPicker } from '@ui/EmojiPicker';
import { Flex } from '@ui/Flex';
import { ReactComponent as TrashIcon } from '@ui/_deprecated/Icon/icons/ic_trash.svg';
import { Input } from '@ui/Input';
import { SymbolsLimitBubble } from '@ui/SymbolsLimitBubble';
import { BlocksSelectorData } from '@components/BlocksSelector2';
import { Platform } from '@globals';

import { Permission } from '../../common/services/RoleService';

import { BlocksSelector } from './BlocksSelector';

import { BlockWithPermissions, AiIntentActionItemType } from './types';
import { AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups_intents_action_items as ActionItem } from './@types/AI_SETUP_QUERY';
import { BlockGroupsQuery_bot_blocksGroups_blocks as ActionBlock } from './@types/BlockGroupsQuery';

import * as css from './AiSetupPage.module.less';

interface AiIntentActionProps {
  item: ActionItem;
  index: number;
  onTextChange: (value: string, i: number) => void;
  onBlocksChange: (blocks: ActionBlock[], i: number) => void;
  onRemoveAction: () => void;
  blocksGroups: BlockWithPermissions[];
  autofocus?: boolean;
  currentBotId: string; // TODO Move me to router params or provider after Angular redeem
  platform: Platform;
}

const MAX_INTENT_MESSAGE_LENGTH = 640;

export const AiIntentAction: React.FC<AiIntentActionProps> = (props) => {
  const [firstEdit, setFirstEdit] = React.useState(
    !!props.autofocus && props.item.text === '',
  );
  const [showRemoveButton, setShowRemoveButton] = React.useState(false);

  const renderTextAction = () => {
    const { item, onTextChange, index, autofocus } = props;

    return (
      <Input
        borderless
        error={!firstEdit && item.text === ''}
        render={({ getInputProps }) => {
          const inputProps = {
            ...getInputProps({
              type: 'text',
              placeholder: window.i18next.t(
                'AiIntentAction-string--553-enter-reply-text',
              ),
              value: item.text || '',
              onBlur: () => setFirstEdit(false),
              className: cn(
                css.actionItemInputLayout,
                'test-intent-reply-text-input',
              ),
              onChange: (event: React.FormEvent<HTMLInputElement>) =>
                onTextChange(event.currentTarget.value, index),
            }),
          };

          return (
            <RoleConsumer domain="ai" can={Permission.EDIT}>
              {({ allowed }) => (
                <EmojiPicker
                  onSelect={(emoji, el) => {
                    insertText(emoji.native, el);
                  }}
                  buttonSpaceWidth={0}
                  oneLineInputHeight={26}
                >
                  {({ bind: { ref, ...emojiPickerBind }, focused }) => (
                    <>
                      <TextareaAutosize
                        data-testid="ai__reply-text-input"
                        disabled={!allowed}
                        autoFocus={autofocus}
                        rows={1}
                        maxRows={5}
                        className={cn(
                          inputProps.className,
                          css.textActionTextarea,
                        )}
                        onBlur={() => {
                          inputProps.onBlur();
                          emojiPickerBind.onBlur();
                        }}
                        onFocus={emojiPickerBind.onFocus}
                        placeholder={inputProps.placeholder}
                        type={inputProps.type}
                        value={
                          inputProps.value as TextareaAutosizeProps['value']
                        }
                        onChange={
                          inputProps.onChange as TextareaAutosizeProps['onChange']
                        }
                        inputRef={(el) => {
                          if (el) {
                            if (ref) {
                              // eslint-disable-next-line no-param-reassign
                              ref.current = el;
                            }
                            if (inputProps.ref) {
                              inputProps.ref.current = el;
                            }
                          }
                        }}
                      />
                      <Flex justifyContent="flex-end" style={{ height: 20 }}>
                        {focused && (
                          <SymbolsLimitBubble
                            value={
                              MAX_INTENT_MESSAGE_LENGTH -
                              inputProps.value.length
                            }
                            style={{
                              margin: '0px 32px 4px 0px',
                            }}
                          />
                        )}
                      </Flex>
                    </>
                  )}
                </EmojiPicker>
              )}
            </RoleConsumer>
          );
        }}
      />
    );
  };

  const renderBlockAction = () => {
    const {
      item,
      blocksGroups,
      autofocus,
      onBlocksChange,
      index,
      currentBotId,
      platform,
    } = props;
    const blocks = item.blocks || [];
    return (
      item.item_type === AiIntentActionItemType.block && (
        <Input
          borderless
          error={!firstEdit && blocks.length === 0}
          onBlur={() => setFirstEdit(false)}
          render={() => (
            <RoleConsumer domain="ai" can={Permission.EDIT}>
              {({ allowed }) => (
                <BlocksSelectorData botId={currentBotId}>
                  {({ flowsGroups }) => (
                    <BlocksSelector
                      data-testid="ai__reply-blocks-selector"
                      borderless
                      blocksGroups={blocksGroups.filter(
                        (group) => !group.hidden,
                      )}
                      flowsGroups={flowsGroups}
                      blocksSelected={blocks}
                      onChange={(blocks: ActionBlock[]) =>
                        onBlocksChange(blocks, index)
                      }
                      autofocus={autofocus}
                      readonly={!allowed}
                      currentBotId={currentBotId}
                      platform={platform}
                      hideTips
                      enablePortal
                    />
                  )}
                </BlocksSelectorData>
              )}
            </RoleConsumer>
          )}
        />
      )
    );
  };

  const handleMouseLeave = () => {
    setShowRemoveButton(false);
  };

  const handleMouseOver = () => {
    setShowRemoveButton(true);
  };

  const { item, onRemoveAction } = props;
  return (
    <div
      className={css.actionContainer}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {item.item_type === AiIntentActionItemType.text && renderTextAction()}
      {item.item_type === AiIntentActionItemType.block && renderBlockAction()}
      <RoleConsumer domain="ai" can={Permission.EDIT}>
        <div
          className={css.removeActionButtonContainer}
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {showRemoveButton && (
            <div className={css.removeActionButton}>
              <Button
                intent={ButtonIntent.default}
                size={ButtonSize.s}
                tall
                renderIcon={() => <TrashIcon />}
                onClick={onRemoveAction}
              />
            </div>
          )}
        </div>
      </RoleConsumer>
    </div>
  );
};
