import { useBlocksGroupsWithPermissions } from '@utils/Data/Blocks/useBlocksGroupsWithPermissions';
import React, { useState } from 'react';
import cn from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Level, log } from 'cf-common/src/logger';

import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { useCurrentBotId } from '@utils/Routing';
import { useRolePermission } from '@utils/Roles';
import { ToasterContainer } from '@components/ToasterContainer';
import { toaster } from '@services/MessageService';

import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { Permission } from '../../common/services/RoleService';

import { AiDefaultAnswer } from './AiDefaultAnswer';
import { LanguageSelector } from './LanguageSelector';
import { AiGroups } from './AiGroups';
import { AiSetupPageLoader } from './AiSetupPageLoader';
import { AiSearchField } from './AiSearchField';

import { AI_SETUP_QUERY } from './queries';

import {
  AI_SETUP_QUERY as AI_SETUP_QUERYTypes,
  AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin,
  AI_SETUP_QUERYVariables,
} from './@types/AI_SETUP_QUERY';

import * as css from './AiSetupPage.module.less';

export const AiSetupPage: React.FC = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  // TODO revert after fix
  const { allowed: isViewAllowed } = useRolePermission({
    domain: 'groups',
    can: Permission.VIEW,
  });
  const { allowed: isEditAllowed } = useRolePermission({
    domain: 'ai',
    can: Permission.EDIT,
  });

  const [intentsFilterTerm, setIntentsFilterTerm] = useState('');

  const { isAutomateEnabled } = useAutomateEnabled();

  const { data, loading, error } = useQuery<
    AI_SETUP_QUERYTypes,
    AI_SETUP_QUERYVariables
  >(AI_SETUP_QUERY, {
    variables: { botId },
    skip: !isViewAllowed,
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (!data?.bot?.aiBlock?.cards?.[0]) {
        log({
          msg: 'Invalid AI card',
          level: Level.warn,
        });
      }
    },
  });

  const { blocksGroupsWithPermissions } = useBlocksGroupsWithPermissions();

  if (!botId || !isViewAllowed) {
    return null;
  }

  if (loading) {
    return <AiSetupPageLoader />;
  }

  if (error || !data) {
    return (
      <span>
        {window.i18next.t('AiSetupPage-JSXText-1587-error-loading-try-later')}
      </span>
    );
  }

  const {
    bot: {
      languages,
      aiBlock: { cards },
    },
    bots,
  } = data;

  if (!cards || !cards[0]) {
    return (
      <span>
        {window.i18next.t('AiSetupPage-JSXText-1577-invalid-ai-card')}
      </span>
    );
  }

  const { config, id: cardId } =
    cards[0] as AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin;
  const { lang: selectedLanguageCode, groups } = config;

  return (
    <div className={cn(css.layout, 'test-ai-setup-page')}>
      <ToasterContainer toaster={toaster} />
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        className={css.inlineLayout}
      >
        <Flex className={css.infoText} flexDirection="column">
          <div className={css.textHeader}>
            <Type as="h4" weight="bold" size="15px_DEPRECATED">
              {t('pages.Ai.title')}
            </Type>
          </div>
          <div className={css.text}>
            <Type color="greyDark" size="15px_DEPRECATED">
              {isAutomateEnabled
                ? t('pages.Ai.descriptionBlock')
                : t('pages.Ai.descriptionFlow')}
            </Type>
            {!isEditAllowed && ' '}
            <LanguageSelector
              cardId={cardId}
              languageItems={languages}
              selectedLanguageCode={selectedLanguageCode}
              className={css.languageSelectorLayout}
              disabled={!isEditAllowed}
            />
          </div>
        </Flex>
        <div className={css.searchField}>
          <AiSearchField
            groups={groups}
            intentsFilterTerm={intentsFilterTerm}
            onIntentsFitlerTermChange={setIntentsFilterTerm}
          />
        </div>
      </Flex>

      <AiDefaultAnswer intentsFilterTerm={intentsFilterTerm} />
      <Spacer factor={6} />
      <Type weight="semibold" size="15px_DEPRECATED">
        {t('pages.Ai.keywordGroups')}
      </Type>
      <Spacer factor={2} />

      <AiGroups
        cardId={cardId}
        groups={groups}
        bots={bots}
        currentBotId={botId}
        blocksGroups={blocksGroupsWithPermissions}
        intentsFilterTerm={intentsFilterTerm}
        onBeforeAddIntent={() => {
          setIntentsFilterTerm('');
        }}
      />
    </div>
  );
};
