import cn from 'classnames';
import gql from 'graphql-tag';
import i18next from 'i18next';
import nanoid from 'nanoid';
import {
  complement,
  differenceWith,
  findIndex,
  path,
  prop,
  propEq,
  propOr,
} from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CreateNewBlockMutation_createBlock_block } from '@components/Aside/Mutations/@types/CreateNewBlockMutation';

import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { usePreventWheel } from '@utils/Event/usePreventWheel';
import { RoleConsumer } from '@utils/Roles';
import {
  BotPageRouteParams,
  useAutomateTabLink,
  useFlowsTabLink,
} from '@utils/Routing';

import { SuggestUi } from '@ui/Suggest';
import { BlockGroupsAndBlocksTitlesQuery_bot_flowGroupsSuggest } from '@components/ButtonEditorPopup/common/@types/BlockGroupsAndBlocksTitlesQuery';
import {
  createBlock,
  getOptimisticBlockId,
  isOptimisticBlock,
} from '@components/Aside/Mutations/BlockMutations';
import { Platform } from '@globals';
import { Permission } from '../../common/services/RoleService';
import {
  BlockGroupsQuery_bot_blocksGroups as BlocksGroup,
  BlockGroupsQuery_bot_blocksGroups_blocks,
} from './@types/BlockGroupsQuery';
import { BlockWithPermissions } from './types';
import * as css from './AiSetupPage.module.less';
import { useAddFlow, useFlowGroups } from '@utils/Data/Flow';
import { getFirstFlowGroupId } from '@utils/Data/Flow/GroupingFlows/utils';
import Maybe from 'graphql/tsutils/Maybe';

type ItemType = 'group' | 'item' | '_separator' | 'title';

export const BlockSelectorFragment = {
  blocks: gql`
    fragment BlocksSelector_blocks on BlocksGroup {
      blocks {
        id
        title
        removed
        is_flow
      }
    }
  `,
};

export const BlockSelectorQuery = {
  blocksGroups: gql`
    fragment BlocksSelector_blocksGroups on Bot {
      blocksGroups {
        id
        title
        builtin
        sequence
        synced_from {
          bot {
            title
          }
        }
        ...BlocksSelector_blocks
      }
    }
    ${BlockSelectorFragment.blocks}
  `,
};

interface SuggestItem {
  id?: string;
  title?: string;
  type?: ItemType;
  tooltip?: React.ReactNode;
}

export enum BlocksSelectorMode {
  block = 'block',
  flow = 'flow',
  all = 'all',
}

export interface BlocksSelectorProps extends TestLocator {
  blocksSelected: BlockGroupsQuery_bot_blocksGroups_blocks[];
  blocksGroups: BlockWithPermissions[];
  flowsGroups?: BlockGroupsAndBlocksTitlesQuery_bot_flowGroupsSuggest[];
  onChange: (
    blocksSelected: BlockGroupsQuery_bot_blocksGroups_blocks[],
  ) => void;
  onGoToBlock?: (
    block?: BlockGroupsQuery_bot_blocksGroups_blocks,
  ) => void | boolean;
  autofocus?: boolean;
  currentBotId: string;
  readonly?: boolean;
  borderless?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onAdded?: (block: BlockGroupsQuery_bot_blocksGroups_blocks) => void;
  error?: boolean;
  placeholder?: string;
  hideTips?: boolean;
  enablePortal?: boolean;
  disableDropdown?: boolean;
  inputWithSelectedBlocksClassName?: string;
  openBlockInNewTab?: boolean;
  platform: Platform;
  mode?: BlocksSelectorMode;
  inputRef?: React.RefObject<HTMLInputElement>;
  rightElement?: React.ReactNode;
  useButtonForMobile?: boolean;
}

const defaultItemType: ItemType = 'item';

const differenceWithId = differenceWith(
  (x: SuggestItem, y: SuggestItem) => x.id === y.id,
);

const isNotOptimisticBlock = complement(isOptimisticBlock);

const isPlatformNotFacebook = (platform: Maybe<Platform>) =>
  Boolean(
    platform && [Platform.instagram, Platform.whatsapp].includes(platform),
  );

const filterAndFlattenItems = (
  blocksGroups: BlocksGroup[],
  flowsGroups: BlockGroupsAndBlocksTitlesQuery_bot_flowGroupsSuggest[] = [],
  blocksSelected: BlockGroupsQuery_bot_blocksGroups_blocks[],
  mode: BlocksSelectorMode | undefined,
  platform: Platform | null,
  isAutomateEnabled: boolean,
) => {
  return (
    isAutomateEnabled && !isPlatformNotFacebook(platform)
      ? [
          {
            id: nanoid(),
            title: i18next.t('modernUi.BlocksSelector.Flows'),
            type: '_separator',
          } as SuggestItem,
        ]
      : []
  ).concat([
    ...(mode === BlocksSelectorMode.block ||
    (mode === BlocksSelectorMode.flow && blocksSelected.length > 0)
      ? []
      : flowsGroups.reduce((items: SuggestItem[], group) => {
          if (!group.flows || !group.flows.length) {
            return items;
          }

          const diffWithSelected = differenceWithId(
            group.flows
              .filter(isNotOptimisticBlock)
              .filter(
                (item) => platform === null || item.platform === platform,
              ),
            blocksSelected,
          );

          if (!diffWithSelected.length) {
            return items;
          }

          items.push({
            id: group.id,
            title: group.title,
            type: 'group',
          } as SuggestItem);

          items.push(...diffWithSelected);

          return items;
        }, [])),
    {
      id: nanoid(),
      title: i18next.t('BlocksSelector-string-1992-blocks'),
      type: '_separator',
    } as SuggestItem,
    ...(mode === BlocksSelectorMode.flow || isPlatformNotFacebook(platform)
      ? []
      : blocksGroups.reduce((items: SuggestItem[], group: BlocksGroup) => {
          if (!group.blocks || !group.blocks.length) {
            return items;
          }

          const diffWithSelected = differenceWithId(
            group.blocks.filter(isNotOptimisticBlock),
            blocksSelected,
          );

          if (!diffWithSelected.length) {
            return items;
          }

          items.push({
            id: group.id,
            title: group.title,
            type: 'group',
          } as SuggestItem);

          items.push(...diffWithSelected);

          return items;
        }, [])),
  ]);
};

type CreatedBlock = {
  block: CreateNewBlockMutation_createBlock_block;
  optimisticId: string;
};

const findBlockIndexById = (
  id: string,
  blocks: BlockGroupsQuery_bot_blocksGroups_blocks[],
) => findIndex(propEq('id', id), blocks);

export const BlocksSelector: React.FC<BlocksSelectorProps> = ({
  blocksSelected,
  blocksGroups,
  flowsGroups,
  onChange,
  onGoToBlock,
  autofocus,
  currentBotId,
  readonly,
  borderless,
  onFocus,
  onBlur,
  error,
  placeholder,
  hideTips,
  enablePortal,
  disableDropdown,
  onAdded,
  openBlockInNewTab,
  platform,
  mode: forcedMode,
  inputRef,
  rightElement,
  useButtonForMobile,
  ...props
}) => {
  const history = useHistory();
  const matchAutomateTab = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/structure/:blockId',
  );
  const currentBlockId = matchAutomateTab?.params.blockId;
  const preventWheelRef = usePreventWheel();
  const [showAddBlockItemPanel, setShowAddBlockItemPanel] = useState(false);
  const [showAddFlowItemPanel, setShowAddFlowItemPanel] = useState(false);
  const [mode, setMode] = useState<BlocksSelectorMode | undefined>();
  const getLinkToBlock = useAutomateTabLink();
  const getFlowsToBlock = useFlowsTabLink();
  const [value, setValue] = useState<SuggestItem>({
    type: defaultItemType,
    id: '',
    title: '',
  });
  const { data: flowGroupsData } = useFlowGroups();
  const { addFlow } = useAddFlow();
  const groupId = getFirstFlowGroupId(flowGroupsData?.bot);

  const { isAutomateEnabled } = useAutomateEnabled();

  useEffect(() => {
    if (blocksSelected.length === 0) {
      setMode(undefined);
      return;
    }
    if (forcedMode) {
      setMode(forcedMode);
    } else {
      const ids = blocksSelected.map(prop('id'));
      const isFlowMode =
        (!!flowsGroups &&
          flowsGroups.some(({ flows }) =>
            (flows || []).some(({ id }) => ids.includes(id)),
          )) ||
        blocksSelected.some(({ is_flow: isFlow }) => isFlow);
      setMode(isFlowMode ? BlocksSelectorMode.flow : BlocksSelectorMode.block);
    }
  }, [blocksSelected, flowsGroups, forcedMode]);

  // buffer of created blocks which contain optimistic ids in external data
  const [createdBlocks, setCreatedBlocks] = useState<CreatedBlock[]>([]);

  const handleInputChange = useCallback(
    (title: string) => {
      setValue({
        title,
        type: 'item',
        id: title,
      });
      const blockItemsTitles = blocksGroups.flatMap(({ blocks }) =>
        blocks.map(({ title }) => title.toLowerCase()),
      );

      const flowItemsTitles = flowsGroups?.flatMap(({ flows }) =>
        (flows ?? []).map(({ title }) => title.toLowerCase()),
      );
      setShowAddBlockItemPanel(
        title.length > 0 && !blockItemsTitles.includes(title.toLowerCase()),
      );
      setShowAddFlowItemPanel(
        title.length > 0 && !flowItemsTitles?.includes(title.toLowerCase()),
      );
    },
    [blocksGroups, flowsGroups],
  );

  const updateItems = useCallback(
    (
      blocksSelectedInner: BlockGroupsQuery_bot_blocksGroups_blocks[],
      resetInput?: boolean,
    ) => {
      setValue(
        resetInput
          ? {
              type: 'item',
              id: '',
              title: '',
            }
          : value,
      );
      setShowAddBlockItemPanel(showAddBlockItemPanel && !resetInput);
      setShowAddFlowItemPanel(showAddFlowItemPanel && !resetInput);
      onChange(blocksSelectedInner);
    },
    [onChange, showAddBlockItemPanel, showAddFlowItemPanel, value],
  );

  const deselectItem = useCallback(
    (title: string) => {
      const blocksSelectedCopy = [...blocksSelected];
      const index = findIndex(propEq('title', title))(blocksSelectedCopy);
      blocksSelectedCopy.splice(index, 1);
      updateItems(blocksSelectedCopy);
    },
    [blocksSelected, updateItems],
  );

  const selectItem = useCallback(
    (_, { id }: SuggestItem) => {
      if (!id) {
        return;
      }
      const blocksSelectedCopy = [...blocksSelected];
      const block = [...blocksGroups, ...(flowsGroups || [])]
        .reduce(
          (agg: BlockGroupsQuery_bot_blocksGroups_blocks[], group: any) => [
            ...agg,
            ...(group.blocks || group.flows),
          ],
          [],
        )
        .find(
          (block: BlockGroupsQuery_bot_blocksGroups_blocks) => block.id === id,
        );
      if (block) {
        blocksSelectedCopy.push({
          ...block,
          __typename: 'Block',
          removed: false,
        });
      }
      updateItems(blocksSelectedCopy, true);
    },
    [blocksSelected, blocksGroups, flowsGroups, updateItems],
  );

  // we collect created blocks with optimistic ids and change them to real ones during the updates of props
  useEffect(() => {
    createdBlocks.forEach(({ block, optimisticId }) => {
      const updatedBlocksSelected = [...blocksSelected];
      const newSelectedBlockIndex = findBlockIndexById(
        optimisticId,
        updatedBlocksSelected,
      );
      if (newSelectedBlockIndex !== -1) {
        updatedBlocksSelected[newSelectedBlockIndex] = {
          __typename: 'Block',
          id: block.id,
          title: block.title,
          removed: block.removed,
          is_flow: block.is_flow,
        };
        onAdded?.(updatedBlocksSelected[newSelectedBlockIndex]);
        setCreatedBlocks(
          createdBlocks.filter((item) => item.optimisticId !== optimisticId),
        );
        onChange(updatedBlocksSelected);
      }
    });
  }, [blocksSelected, onChange, createdBlocks, onAdded]);

  const addNewFlow = useCallback(
    async (title: string) => {
      const optimisticFlowId = getOptimisticBlockId();
      const optimisticFlow = {
        __typename: 'Block',
        title,
        id: optimisticFlowId,
        is_flow: true,
        removed: false,
      } as BlockGroupsQuery_bot_blocksGroups_blocks;
      setValue({
        type: defaultItemType,
        id: '',
        title: '',
      });
      onAdded?.(optimisticFlow);
      onChange([...blocksSelected, optimisticFlow]);
      const addFlowResult = await addFlow({
        parentGroupId: groupId,
        platform,
        flowTitle: title,
      });
      const newFlowId = addFlowResult?.addFlow.id;
      if (!newFlowId) {
        return;
      }
      const newFlow = {
        ...optimisticFlow,
        id: newFlowId,
      };
      onAdded?.(newFlow);
      onChange([...blocksSelected, newFlow]);
    },
    [addFlow, blocksSelected, groupId, onAdded, onChange, platform],
  );

  const addNewBlock = useCallback(
    async (
      title: string,
      blockId?: string,
    ): Promise<BlockGroupsQuery_bot_blocksGroups_blocks | null> => {
      const blocksSelectedCopy = [...blocksSelected];
      const optimisticBlockId = blockId || getOptimisticBlockId();

      const optimisticBlock = {
        __typename: 'Block',
        title,
        id: optimisticBlockId,
        is_flow: false,
        removed: false,
      } as BlockGroupsQuery_bot_blocksGroups_blocks;

      onAdded?.(optimisticBlock);

      setValue({
        type: defaultItemType,
        id: '',
        title: '',
      });

      if (blockId) {
        const optimisticBlockIndex = findBlockIndexById(
          optimisticBlockId,
          blocksSelectedCopy,
        );
        blocksSelectedCopy[optimisticBlockIndex] = optimisticBlock;
      } else {
        blocksSelectedCopy.push(optimisticBlock);
      }
      onChange(blocksSelectedCopy);

      const inWritableGroup = (group: BlockWithPermissions) => {
        return group && !group.builtin && !group.sequence && !group.synced_from;
      };

      const optimisticGroupInner =
        (currentBlockId &&
          blocksGroups.find(
            (group) =>
              inWritableGroup(group) &&
              group.blocks.some(({ id }) => id === currentBlockId),
          )) ||
        blocksGroups.find(inWritableGroup);

      let optimisticGroup;
      if (optimisticGroupInner) {
        optimisticGroup = {
          ...optimisticGroupInner!,
          collapsed: null,
          total_clicks: null,
          total_sent: null,
          total_users: null,
          total_views: null,
          synced_from: null,
          with_stats: null,
          blocks: optimisticGroupInner.blocks
            .filter((block) => block)
            .map((block) => ({
              ...block,
              builtin: null,
              referral: null,
              referral_active: null,
              is_valid: true,
              stats: null,
              broadcast_options: null,
              total_clicks: null,
              total_views: null,
            })),
        };
      }

      const createBlockResult = await createBlock({
        title,
        optimisticBlockId,
        botId: currentBotId,
        silent: true,
        group: optimisticGroup,
        history,
      });

      if (createBlockResult) {
        const createBlockResultData = createBlockResult.data;
        const newBlock = path<CreateNewBlockMutation_createBlock_block>(
          ['createBlock', 'block'],
          createBlockResultData,
        );
        if (newBlock) {
          setCreatedBlocks([
            ...createdBlocks,
            {
              block: newBlock,
              optimisticId: optimisticBlockId,
            },
          ]);

          onAdded?.(newBlock);
          return newBlock;
        }
      }
      return null;
    },
    [
      blocksSelected,
      onAdded,
      onChange,
      blocksGroups,
      currentBotId,
      history,
      createdBlocks,
      currentBlockId,
    ],
  );

  const items: SuggestItem[] = useMemo(
    () =>
      filterAndFlattenItems(
        blocksGroups,
        flowsGroups,
        blocksSelected,
        mode,
        platform,
        isAutomateEnabled,
      ),
    [
      blocksGroups,
      flowsGroups,
      blocksSelected,
      mode,
      platform,
      isAutomateEnabled,
    ],
  );

  const redirectToUrl = (url: string) => {
    if (openBlockInNewTab) {
      window.open(url, '_blank');
    } else {
      history.push(url);
    }
  };

  return (
    <RoleConsumer domain="groups" can={Permission.EDIT}>
      {({ allowed }) => (
        <SuggestUi<SuggestItem>
          data-testid={props['data-testid']}
          inputRef={inputRef}
          emptyInputPlaceholder={placeholder}
          readonly={readonly}
          items={items}
          selectedItem={value}
          selectedItems={blocksSelected}
          displaySelected
          borderless={borderless}
          onChange={selectItem}
          onInputChange={handleInputChange}
          onDeselectItem={deselectItem}
          getItemType={propOr('item', 'type')}
          itemToString={propOr('', 'title')}
          showAddBlockPanel={
            isAutomateEnabled &&
            !isPlatformNotFacebook(platform) &&
            showAddBlockItemPanel &&
            allowed &&
            mode !== BlocksSelectorMode.flow
          }
          showAddFlowPanel={
            showAddFlowItemPanel && allowed && mode !== BlocksSelectorMode.block
          }
          onAddNewBlock={addNewBlock}
          onAddNewFlow={addNewFlow}
          className={cn(css.inputSpacing, 'test-blocks-suggest')}
          inputWithSelectedBlocksClassName={
            props.inputWithSelectedBlocksClassName
          }
          autoCloseDropdown={false}
          autofocus={autofocus}
          autofocusItem={mode === BlocksSelectorMode.flow}
          onInputFocus={onFocus}
          onInputBlur={onBlur}
          invalid={error}
          disableLinkPad={hideTips}
          enablePortal={enablePortal}
          dropdownRef={preventWheelRef}
          disableDropdown={disableDropdown}
          platform={platform}
          rightElement={rightElement}
          onTagDoubleClick={async (item) => {
            if (!item) {
              return;
            }

            const block = item as BlockGroupsQuery_bot_blocksGroups_blocks;

            if (block.is_flow) {
              redirectToUrl(getFlowsToBlock(block.id));
              return;
            }

            // we have to do this because backend couldn't migrate all data properly
            const reachableItems = filterAndFlattenItems(
              blocksGroups,
              [],
              blocksSelected,
              mode,
              platform,
              isAutomateEnabled,
            ).filter(
              ({ type = '' }) => !['group', '_separator'].includes(type),
            ) as BlockGroupsQuery_bot_blocksGroups_blocks[];

            const blockWithTheSameTitle = reachableItems.find(
              (b) => b.title === block.title && !b.removed,
            );

            if (block.removed && !blockWithTheSameTitle) {
              const createdBlock = await addNewBlock(block.title, block.id);
              if (createdBlock) {
                if (createdBlock && onGoToBlock?.(createdBlock) !== false) {
                  redirectToUrl(getLinkToBlock(createdBlock.id));
                }
              }
            }

            const curBlock = block.removed ? blockWithTheSameTitle : block;

            if (!(onGoToBlock?.(curBlock) === false) && curBlock) {
              redirectToUrl(getLinkToBlock(curBlock.id));
            }
          }}
          useButtonForMobile={useButtonForMobile}
        />
      )}
    </RoleConsumer>
  );
};
