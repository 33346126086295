/* eslint-disable no-use-before-define,no-param-reassign,react/sort-comp */
import React from 'react';
import { RemoveEntityDialog } from '@components/RemoveEntityDialog';
import { TextButton } from '@ui/_deprecated/Button';
import { Dropdown } from '@ui/_deprecated/Dropdown';
import { ReactComponent as AddIcon } from '@ui/_deprecated/Icon/icons/ic_add_small.svg';
import { ReactComponent as Dots } from '@ui/_deprecated/Icon/icons/ic_dots.svg';
import { Box } from '@ui/Box';
import { BubbleEditor } from '@ui/BubbleEditor';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import Label from '@ui/Label/Label';

import { Modal } from '@ui/Modal';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { sendEvent } from '@utils/Analytics';
import { AutomateEnabled } from '@utils/Data/Admin/Automate';
import cn from 'classnames';
import { GetToggleButtonPropsOptions } from 'downshift';
import gql from 'graphql-tag';
import i18next from 'i18next';
import debounce from 'lodash-es/debounce';

import {
  allPass,
  anyPass,
  clone,
  compose,
  findIndex,
  head as first,
  isEmpty,
  last,
  path,
  pipe,
  prop,
  propEq,
  remove,
} from 'ramda';
import { removeTypename } from '@utils/GQL/utils';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Button } from '@ui/Button';
import { MenuItem } from '@ui/Menu';
import { RoleConsumer } from '@utils/Roles';
import { Tooltip2 } from '@ui/Tooltip2';
import { combineRefs } from '@utils/combineRefs';
import { AiIntentFilterType, Platform } from '@globals';

import client from '../../common/services/ApolloService';
import OneElementQueue from '../../common/services/OneElementQueueService.js';
import { Permission } from '../../common/services/RoleService';

import { ConfirmMoveIntentModal } from './ConfirmMoveIntentModal';
import { AiIntentAction } from './AiIntentAction';

import { AiIntentActionItemType, BlockWithPermissions } from './types';
import {
  AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups as Group,
  AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups_intents as AIIntent,
  AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups_intents_action_items_blocks as Block,
} from './@types/AI_SETUP_QUERY';

import * as css from './AiSetupPage.module.less';
import { getAiRuleTypeTitles, getAiRuleTypeTooltips } from './helpers';
import { AI_INTENT_FRAGMENT } from '@utils/Data/Ai/common/queries';
import { getUpdateAiIntentCacheUpdater } from '@utils/Data/Ai/Groups/helpers';

export interface AiIntentProps {
  intent: AIIntent;
  cardId: string;
  groupId: string;
  blocksGroups: BlockWithPermissions[];
  groups: Group[];
  autofocus: boolean;
  onMoveIntentToGroup?: (groupId: string) => void;
  currentBotId: string; // TODO Move me to router params or provider after Angular redeem
}

interface AiIntentState {
  hoverActionItemIndex: number | null;
  hoverIntent: boolean;
  showPopup: boolean;
  newItemAdded: boolean;
  actionType: string;
}

type HandlerMapItem = {
  menuTitle: string;
  handler: () => void;
};

type HandlerMap = {
  remove: HandlerMapItem;
  moveTo: HandlerMapItem;
};

const intentsFragment = gql`
  fragment intents on AiGroup {
    intents {
      id
    }
  }
`;

const intentWriteToCache = (intent: AIIntent) => {
  client.writeFragment({
    id: intent.id,
    fragmentName: 'AiIntent_intent',
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    fragment: AiIntent.query.intent,
    data: intent,
  });
};

const oneElementQueue = new OneElementQueue();

const intentSave = (
  newIntent: AIIntent,
  cardId: string,
  groupId: string,
  botId: string,
) => {
  const queueOperator = oneElementQueue.getQueue(newIntent.id, client.mutate);
  const mutateOptions = {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    mutation: AiIntent.mutation.updateAiIntent,
    variables: {
      botId,
      cardId,
      groupId,
      intent: removeTypename(newIntent),
    },
    update: getUpdateAiIntentCacheUpdater(groupId),
  };
  queueOperator.queue(mutateOptions);
};

const intentSaveDebounce = debounce(intentSave, 300);
const findIntentIndexById = (id: string, list: AIIntent[]) =>
  findIndex(propEq('id', id), list);

const differShallowWithMutableArray =
  (newProps: any, oldProps: any) => (prop: string) => {
    if (newProps[prop] === oldProps[prop]) {
      return false;
    }

    if (
      Array.isArray(newProps[prop]) &&
      Array.isArray(oldProps[prop]) &&
      newProps[prop].length === oldProps[prop].length
    ) {
      return newProps[prop].some((item: any, i: number) => {
        if (item === oldProps[prop][i]) {
          return false;
        }

        // hack for current group was changed, but we are not interested in it
        if (prop === 'groups' && item.id === newProps.groupId) {
          return false;
        }

        return true;
      });
    }

    return true;
  };

const isEmptyIntent = allPass([
  pipe(
    prop('lines'),
    anyPass([isEmpty, allPass([propEq('length', 1), compose(isEmpty, first)])]),
  ),
  pipe(path(['action', 'items']), isEmpty),
]);

export class AiIntent extends React.Component<AiIntentProps, AiIntentState> {
  static query = {
    intent: gql`
      fragment AiIntent_intent on AiIntent {
        __typename
        name
        filter_type
        lines
        wa_action {
          __typename
          random
          items {
            __typename
            item_type
            blocks {
              __typename
              id
              title
              removed
              is_flow
            }
            text
          }
        }
        ig_action {
          __typename
          random
          items {
            __typename
            item_type
            blocks {
              __typename
              id
              title
              removed
              is_flow
            }
            text
          }
        }
        action {
          __typename
          random
          items {
            __typename
            item_type
            blocks {
              __typename
              id
              title
              removed
              is_flow
            }
            text
          }
        }
      }
    `,
  };

  static mutation = {
    removeAiIntent: gql`
      mutation REMOVE_AI_INTENT(
        $cardId: String!
        $groupId: String!
        $intentId: String!
      ) {
        removeAiIntent(cardId: $cardId, groupId: $groupId, intentId: $intentId)
      }
    `,
    updateAiIntent: gql`
      mutation UPDATE_AI_INTENT(
        $cardId: String!
        $groupId: String!
        $botId: String!
        $intent: AiIntentInput!
      ) {
        updateAiIntent(
          cardId: $cardId
          groupId: $groupId
          intent: $intent
          botId: $botId
        ) {
          ...aiIntentFragment
        }
      }
      ${AI_INTENT_FRAGMENT}
    `,
    moveAiIntent: gql`
      mutation MOVE_AI_INTENT(
        $cardId: String!
        $groupId: String!
        $intentId: String!
        $newGroupId: String!
      ) {
        moveIntentToGroup(
          cardId: $cardId
          fromGroupId: $groupId
          intentId: $intentId
          toGroupId: $newGroupId
        )
      }
    `,
  };

  state = {
    // eslint-disable-next-line react/no-unused-state
    hoverActionItemIndex: null,
    hoverIntent: false,
    showPopup: false,
    newItemAdded: false,
    actionType: '',
  };

  editorRef: BubbleEditor | null = null;

  shouldComponentUpdate(nextProps: AiIntentProps, nextState: AiIntentState) {
    const props = Object.keys(nextProps);

    if (this.state !== nextState) {
      return true;
    }

    return props.some(differShallowWithMutableArray(nextProps, this.props));
  }

  componentDidMount() {
    if (this.props.autofocus && this.editorRef) {
      window.scrollTo({
        top: 0,
        // behavior: 'instant',
      });
      this.editorRef!.focus();
    }
  }

  // eslint-disable-next-line no-return-assign
  setEditorRef = (editorRef: BubbleEditor | null) =>
    (this.editorRef = editorRef);

  handleRemoveIntentClick = () => {
    const { intent } = this.props;
    if (isEmptyIntent(intent)) {
      this.removeIntent();
    } else {
      this.setState({
        showPopup: true,
        actionType: 'delete',
      });
    }
  };

  removeIntent = () => {
    this.handleDismiss();
    const { cardId, groupId, intent } = this.props;

    const intentId = intent.id;
    client.mutate({
      mutation: AiIntent.mutation.removeAiIntent,
      variables: {
        cardId,
        groupId,
        intentId,
      },
      optimisticResponse: {
        removeAiIntent: true,
      },
      update: (store, { data }) => {
        if (data && data.removeAiIntent) {
          const intentsData: Group | null = store.readFragment({
            id: groupId,
            fragment: intentsFragment,
          });
          if (intentsData) {
            const {
              intents: [...intents],
            } = intentsData;
            const intentIndex = findIntentIndexById(intent.id, intents);
            intents.splice(intentIndex, 1);
            intentsData.intents = intents;
            store.writeFragment({
              id: groupId,
              fragment: intentsFragment,
              data: intentsData,
            });
          }
        }
      },
    });
  };

  moveIntentToGroup(newGroupId: string) {
    const { intent, cardId, groupId, onMoveIntentToGroup } = this.props;

    client.mutate({
      mutation: AiIntent.mutation.moveAiIntent,
      variables: {
        cardId,
        groupId,
        newGroupId,
        intentId: intent.id,
      },
      optimisticResponse: {
        moveIntentToGroup: true,
      },
      update: (store, { data }) => {
        if (data && data.moveIntentToGroup) {
          const intentsData: Group | null = store.readFragment({
            id: groupId,
            fragment: intentsFragment,
          });

          const newIntentsData: Group | null = store.readFragment({
            id: newGroupId,
            fragment: intentsFragment,
          });

          if (intentsData) {
            const {
              intents: [...intents],
            } = intentsData;
            const intentIndex = findIntentIndexById(intent.id, intents);
            intents.splice(intentIndex, 1);
            intentsData.intents = intents;
            store.writeFragment({
              id: groupId,
              fragment: intentsFragment,
              data: intentsData,
            });
          }

          if (newIntentsData) {
            const {
              intents: [...newGroupIntents],
            } = newIntentsData;
            newIntentsData.intents = [intent, ...newGroupIntents];
            store.writeFragment({
              id: newGroupId,
              fragment: intentsFragment,
              data: newIntentsData,
            });
          }

          if (onMoveIntentToGroup) {
            onMoveIntentToGroup(newGroupId);
          }
        }
      },
    });
  }

  updateIntent(intentDataSetter: (intent: AIIntent) => void) {
    const { intent, cardId, groupId, currentBotId: botId } = this.props;
    const newIntent = clone(intent);
    intentDataSetter(newIntent);
    intentWriteToCache(newIntent);
    intentSaveDebounce(newIntent, cardId, groupId, botId);
  }

  handleActionTextChange = (value: string, i: number) => {
    this.updateIntent((intent) => {
      intent.action.items[i]!.text = value;
    });
  };

  handleIgActionTextChange = (value: string, i: number) => {
    this.updateIntent((intent) => {
      intent.ig_action.items[i]!.text = value;
    });
  };

  handleActionBlocksChange = (blocks: Block[], i: number) => {
    this.updateIntent((intent) => {
      intent.action.items[i]!.blocks = blocks;
    });
  };

  handleIgActionBlocksChange = (blocks: Block[], i: number) => {
    this.updateIntent((intent) => {
      intent.ig_action.items[i]!.blocks = blocks;
    });
  };

  handleLinesChange = debounce((lines: string[]) => {
    this.updateIntent((intent) => {
      intent.lines = lines;
    });
  }, 300);

  handleAddItemClick(type: AiIntentActionItemType) {
    this.updateIntent((intent) => {
      if (intent.action.items.length === 1) {
        intent.action.random = true;
      }
      intent.action.items = [
        ...intent.action.items,
        {
          __typename: 'AiIntentActionItem',
          item_type: type,
          blocks: null,
          text: '',
        },
      ];
    });

    this.setState({
      newItemAdded: true,
    });
  }

  handleAddIgItemClick(type: AiIntentActionItemType) {
    this.updateIntent((intent) => {
      if (intent.ig_action.items.length === 1) {
        intent.ig_action.random = true;
      }
      intent.ig_action.items = [
        ...intent.ig_action.items,
        {
          __typename: 'AiIntentActionItem',
          item_type: type,
          blocks: null,
          text: '',
        },
      ];
    });

    this.setState({
      newItemAdded: true,
    });
  }

  handlersMap: HandlerMap = {
    remove: {
      menuTitle: window.i18next.t('AiIntent-string-2043-delete'),
      handler: () => {
        sendEvent({
          category: 'ai rule',
          action: 'delete',
        });

        this.handleRemoveIntentClick();
      },
    },
    moveTo: {
      menuTitle: window.i18next.t('AiIntent-string--621-move-to-group'),
      handler: () => {
        sendEvent({
          category: 'ai rule',
          action: 'move',
        });

        this.setState({
          showPopup: true,
          actionType: 'move',
        });
      },
    },
  };

  handleMoveIntentToGroup = (params: { groupId: string }) => {
    this.handleDismiss();
    this.setState({
      showPopup: false,
      actionType: '',
    });
    if (params.groupId !== this.props.groupId) {
      this.moveIntentToGroup(params.groupId);
    }
  };

  handleMenuCLick = (command: string) => {
    // eslint-disable-next-line default-case
    switch (command) {
      case this.handlersMap.remove.menuTitle:
        this.handlersMap.remove.handler();
        break;

      case this.handlersMap.moveTo.menuTitle:
        this.handlersMap.moveTo.handler();
        break;
    }
  };

  handleActionItemRemoveClick(i: number) {
    this.updateIntent((intent) => {
      intent.action.items = remove(i, 1, intent.action.items);
    });
  }

  handleIgActionItemRemoveClick(i: number) {
    this.updateIntent((intent) => {
      intent.ig_action.items = remove(i, 1, intent.ig_action.items);
    });
  }

  handleActionItemHoverState = debounce(
    (hoverActionItemIndex: number | null) => {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ hoverActionItemIndex });
    },
    50,
  );

  handleIntentHoverState = debounce((hoverIntent: boolean) => {
    this.setState({ hoverIntent });
  }, 50);

  handleDismiss = () => this.setState({ showPopup: false, actionType: '' });

  renderDotsButton = (
    props: GetToggleButtonPropsOptions,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <div className={css.menuDotsWrapper} ref={ref}>
        <button
          data-testid="ai__intent-dots-button"
          className={css.menuDots}
          {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        >
          <Dots />
        </button>
      </div>
    );
  };

  render() {
    const { intent, blocksGroups, groups, currentBotId } = this.props;
    const { newItemAdded } = this.state;

    const handlers = [this.handlersMap.remove.menuTitle];
    if (this.props.groups.length > 1) {
      handlers.push(this.handlersMap.moveTo.menuTitle);
    }

    return (
      <div>
        <Box
          className={cn(css.intent_layout, 'test-intent-item')}
          onMouseEnter={() => this.handleIntentHoverState(true)}
          onMouseLeave={() => this.handleIntentHoverState(false)}
        >
          <div className={css.linesContainer}>
            <Type weight="semibold" as="p" size="15px_DEPRECATED">
              {i18next.t('pages.Ai.TypeInKeywordsThatWillTriggerYourBot')}
            </Type>
            <Spacer factor={2} />
            <Flex alignItems="center">
              <Type size="15px">{i18next.t('pages.Ai.rule')}</Type>
              <Spacer factor={1} horizontalFactor={2} />
              <SimpleCombobox<{ id: AiIntentFilterType }>
                renderInput={({ ref, getToggleButtonProps }) => {
                  const currentFilterType =
                    intent.filter_type ?? AiIntentFilterType.default;
                  return (
                    <Tooltip2
                      boundariesElement="viewport"
                      content={
                        <>{getAiRuleTypeTooltips()[currentFilterType]}</>
                      }
                      type="small"
                      placement="right"
                    >
                      {(refTooltip, bind) => (
                        <Button
                          {...getToggleButtonProps()}
                          ref={combineRefs([ref, refTooltip])}
                          intent="secondary"
                          iconRight={<Icon icon="triangle" />}
                          data-testid="ai__rule-type"
                          size="xs"
                          {...bind}
                        >
                          <Type size="15px">
                            {getAiRuleTypeTitles()[currentFilterType]}
                          </Type>
                        </Button>
                      )}
                    </Tooltip2>
                  );
                }}
                items={[
                  { id: AiIntentFilterType.default },
                  { id: AiIntentFilterType.contains },
                ]}
                renderItem={({
                  getItemProps,
                  item,
                  index,
                  highlightedIndex,
                }) => (
                  <MenuItem
                    {...getItemProps({ item })}
                    key={item.id}
                    active={index === highlightedIndex}
                    data-testid={`ai__rule-type_${item.id}-item`}
                    title={getAiRuleTypeTitles()[item.id]}
                    tooltip={<>{getAiRuleTypeTooltips()[item.id]}</>}
                    tooltipType="small"
                    tooltipBoundariesElement="viewport"
                  />
                )}
                onSelect={(item) => {
                  this.updateIntent((intent) => {
                    intent.filter_type = item?.id ?? AiIntentFilterType.default;
                  });
                }}
              />
            </Flex>
            <Spacer factor={2} />
            <Input
              render={() => (
                <RoleConsumer domain="ai" can={Permission.EDIT}>
                  {({ allowed }) => (
                    <BubbleEditor
                      data-testid="ai__text-editor"
                      readonly={!allowed}
                      ref={this.setEditorRef}
                      showControlsPlaceholder
                      autoFocus={this.props.autofocus}
                      className={css.editorContainer}
                      defaultValue={intent.lines.join('\n')}
                      onStringChange={(str) =>
                        this.handleLinesChange(str.split('\n'))
                      }
                      showEmojiPicker
                    />
                  )}
                </RoleConsumer>
              )}
            />
          </div>
          <div className={css.repliesContainer}>
            <>
              <Label data-testid="ai__replies-label" className={css.label}>
                <Flex alignItems="center">
                  <Icon icon="messengerLogo" color="blueDark10" size="12px" />
                  <Spacer horizontalFactor={2} factor={1} />
                  <Type weight="semibold" size="15px_DEPRECATED">
                    {i18next.t('pages.Ai.DefaultAnswer.MessengerReply', {
                      random:
                        intent.action.items.length > 1
                          ? `${i18next.t('pages.Ai.DefaultAnswer.random')} `
                          : '',
                    })}
                  </Type>
                </Flex>
              </Label>
              <div className={css.repliesList}>
                {intent.action.items.map(
                  (item, index, items) =>
                    item && (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index}>
                        <AiIntentAction
                          item={item}
                          index={index}
                          blocksGroups={blocksGroups}
                          onBlocksChange={this.handleActionBlocksChange}
                          onTextChange={this.handleActionTextChange}
                          onRemoveAction={() =>
                            this.handleActionItemRemoveClick(index)
                          }
                          autofocus={newItemAdded && last(items) === item}
                          currentBotId={currentBotId}
                          platform={Platform.facebook}
                        />
                        <div className={css.divider} />
                      </div>
                    ),
                )}
                <RoleConsumer domain="ai" can={Permission.EDIT}>
                  {({ allowed }) => (
                    <div>
                      <div className={cn(css.infoText)}>
                        <AddIcon
                          className={cn(
                            css.iconGray,
                            css.iconInText,
                            css.addIcon,
                          )}
                        />
                        {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                        {window.i18next.t('AiIntent-string-3254-add')}
                        <TextButton
                          data-testid="ai__reply-block-add-button"
                          onClick={() => {
                            sendEvent({
                              category: 'ai rule',
                              action: 'add block',
                              propertyBag: {
                                platform: Platform.facebook,
                              },
                            });

                            this.handleAddItemClick(
                              AiIntentActionItemType.block,
                            );
                          }}
                          className="test-add-reply-block"
                          disabled={!allowed}
                        >
                          <AutomateEnabled>
                            {({ isAutomateEnabled }) =>
                              isAutomateEnabled ? (
                                <>
                                  {window.i18next.t(
                                    'AiIntent-JSXText-1754-block-flow',
                                  )}
                                </>
                              ) : (
                                <>
                                  {window.i18next.t(
                                    'AiIntent-JSXText-2192-flow',
                                  )}
                                </>
                              )
                            }
                          </AutomateEnabled>
                        </TextButton>
                        {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                        {window.i18next.t('AiIntent-string-1063-or')}
                        <TextButton
                          data-testid="ai__reply-text-add-button"
                          onClick={() => {
                            sendEvent({
                              category: 'ai rule',
                              action: 'add text',
                              propertyBag: {
                                platform: Platform.facebook,
                              },
                            });

                            this.handleAddItemClick(
                              AiIntentActionItemType.text,
                            );
                          }}
                          className="test-add-reply-text"
                          disabled={!allowed}
                        >
                          {window.i18next.t('AiIntent-JSXText-1085-text')}
                        </TextButton>
                        {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                        {window.i18next.t('AiIntent-string-1024-reply')}
                      </div>
                    </div>
                  )}
                </RoleConsumer>
              </div>

              <Spacer factor={4} />

              <>
                <Label data-testid="ai__replies-label" className={css.label}>
                  <Flex alignItems="center">
                    <Icon icon="directLogo" color="fuchsia" size="12px" />
                    <Spacer horizontalFactor={2} factor={1} />
                    <Type weight="semibold" size="15px_DEPRECATED">
                      {i18next.t('pages.Ai.DefaultAnswer.InstagramReply', {
                        random:
                          intent.ig_action.items.length > 1
                            ? `${i18next.t('pages.Ai.DefaultAnswer.random')} `
                            : '',
                      })}
                    </Type>
                  </Flex>
                </Label>
                <div className={css.repliesList}>
                  {intent.ig_action.items.map(
                    (item, index, items) =>
                      item && (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index}>
                          <AiIntentAction
                            item={item}
                            index={index}
                            blocksGroups={[]}
                            onBlocksChange={this.handleIgActionBlocksChange}
                            onTextChange={this.handleIgActionTextChange}
                            onRemoveAction={() =>
                              this.handleIgActionItemRemoveClick(index)
                            }
                            autofocus={newItemAdded && last(items) === item}
                            currentBotId={currentBotId}
                            platform={Platform.instagram}
                          />
                          <div className={css.divider} />
                        </div>
                      ),
                  )}
                  <RoleConsumer domain="ai" can={Permission.EDIT}>
                    {({ allowed }) => (
                      <div>
                        <div className={cn(css.infoText)}>
                          <AddIcon
                            className={cn(
                              css.iconGray,
                              css.iconInText,
                              css.addIcon,
                            )}
                          />
                          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                          {window.i18next.t('AiIntent-string-3254-add')}
                          <TextButton
                            data-testid="ai__reply-block-add-button"
                            onClick={() => {
                              sendEvent({
                                category: 'ai rule',
                                action: 'add flow',
                                propertyBag: {
                                  platform: Platform.instagram,
                                },
                              });
                              this.handleAddIgItemClick(
                                AiIntentActionItemType.block,
                              );
                            }}
                            className="test-add-reply-block"
                            disabled={!allowed}
                          >
                            {window.i18next.t('AiIntent-JSXText-6907-flow')}
                          </TextButton>
                          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                          {window.i18next.t('AiIntent-string-1063-or')}
                          <TextButton
                            data-testid="ai__reply-text-add-button"
                            onClick={() => {
                              sendEvent({
                                category: 'ai rule',
                                action: 'add text',
                                propertyBag: {
                                  platform: Platform.instagram,
                                },
                              });
                              this.handleAddIgItemClick(
                                AiIntentActionItemType.text,
                              );
                            }}
                            className="test-add-reply-text"
                            disabled={!allowed}
                          >
                            {window.i18next.t('AiIntent-JSXText-1085-text')}
                          </TextButton>
                          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                          {window.i18next.t('AiIntent-string-1024-reply')}
                        </div>
                      </div>
                    )}
                  </RoleConsumer>
                </div>
              </>
            </>
          </div>
          <RoleConsumer domain="ai" can={Permission.EDIT}>
            {this.state.hoverIntent && (
              <Dropdown
                buttonsFactory={this.renderDotsButton}
                selectedItem=""
                items={handlers}
                onChange={this.handleMenuCLick}
                placement="bottom-end"
              />
            )}
          </RoleConsumer>
        </Box>
        {this.state.showPopup && (
          <Modal
            onDismiss={() =>
              this.setState({
                showPopup: false,
                actionType: '',
              })
            }
          >
            {this.state.actionType === 'move' && (
              <ConfirmMoveIntentModal
                onDismiss={this.handleDismiss}
                onSubmit={this.handleMoveIntentToGroup}
                groups={groups}
                renderActionText={() =>
                  window.i18next.t('AiIntent-string-2404-move')
                }
                renderHeading={() =>
                  window.i18next.t('AiIntent-string--591-move-to-group')
                }
              />
            )}
            {this.state.actionType === 'delete' && (
              <RemoveEntityDialog
                renderHeading={() =>
                  window.i18next.t('AiIntent-string-4873-delete-card')
                }
                renderActionText={() =>
                  window.i18next.t('AiIntent-string-2043-delete')
                }
                onDismiss={this.handleDismiss}
                onSubmit={this.removeIntent}
              />
            )}
          </Modal>
        )}
      </div>
    );
  }
}
