import { BlockGroupsQuery_bot_blocksGroups as BlocksGroup } from './@types/BlockGroupsQuery';
import { AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups as Group } from './@types/AI_SETUP_QUERY';

export interface BlockWithPermissions extends BlocksGroup {
  hidden: boolean;
  synced_from: {
    __typename: 'SyncedFrom';
    bot: {
      __typename: 'SrcBot';
      title: string;
    };
  } | null;
}

export enum AiIntentActionItemType {
  block = 'block',
  text = 'text',
}

export interface GroupExtended extends Group {
  faded?: boolean;
}
