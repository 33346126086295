import React from 'react';
import gql from 'graphql-tag';
import nanoid from 'nanoid';
import { find, findIndex, pathOr, propOr, propEq } from 'ramda';
import debounce from 'lodash-es/debounce';

import { sendEvent } from '@utils/Analytics';
import { RoleConsumer } from '@utils/Roles';

import { TabProps, TabsSet } from '@ui/Tabs';
import { Modal } from '@ui/Modal';
import { ConfirmDeleteGroupDialog } from '@components/ConfirmDeleteGroupDialog';
import { GroupActionsDialog } from '@components/GroupActionsDialog';

import client from '../../common/services/ApolloService';
import { getIncrementName } from '../../common/services/NamingService';
import { Permission } from '../../common/services/RoleService';
import { NoIntentsPlaceholder } from './Placeholder';
import { AiIntentsList } from './AiIntentsList';
import { intentsFilter } from './AiSearchField';

import { BlockWithPermissions, GroupExtended } from './types';
import {
  AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin as AIPlugin,
  AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups as Group,
  AI_SETUP_QUERY_bots,
} from './@types/AI_SETUP_QUERY';

import * as css from './AiSetupPage.module.less';
import { AiIntentFilterType } from '@globals';

interface AiGroupsProps {
  groups: Group[];
  cardId: string;
  bots: AI_SETUP_QUERY_bots[];
  currentBotId: string;
  blocksGroups: BlockWithPermissions[];
  intentsFilterTerm: string;
  onBeforeAddIntent(): void;
}

interface AiGroupsState {
  showPopUp: boolean;
  actionType: string;
  errorMessage?: string;
  activeGroupIndex: number;
}

const findById = (id: string, groups: Group[]) =>
  find(propEq('id', id), groups);
const findIndexById = (id: string, groups: Group[]) =>
  findIndex(propEq('id', id), groups);

const titleProp = propOr('', 'title');

export interface GroupTabsProps extends TabProps {
  isOver?: boolean;
}

export class AiGroups extends React.Component<AiGroupsProps, AiGroupsState> {
  state = {
    // eslint-disable-next-line react/no-unused-state
    activeGroupId: this.props.groups[0].id,
    actionType: '',
    showPopUp: false,
    // eslint-disable-next-line react/no-unused-state
    errorMessage: undefined,
    activeGroupIndex: 0,
  };

  mutateGroupTitle = debounce((groupId: string, groupName: string) => {
    const { cardId } = this.props;
    client.mutate({
      mutation: AiGroups.mutation.updateAiGroupTitle,
      variables: {
        groupId,
        cardId,
        title: groupName,
      },
    });
  }, 300);

  // eslint-disable-next-line react/sort-comp
  static query = {
    groups: gql`
      fragment AiGroups_groups on AIPluginConfig {
        groups {
          ...AiGroupSettings_group
          ...AiIntentsList_intents
        }
      }
      fragment AiGroupSettings_group on AiGroup {
        id
        title
      }
      ${AiIntentsList.query}
    `,
    cardId: gql`
      fragment AiGroup_cardId on AIPlugin {
        id
      }
    `,
    bots: gql`
      fragment AiGroups_bots on Bot {
        id
        title
        status {
          page_info {
            id
            picture
          }
        }
      }
    `,
  };

  cardFragment = gql`
    fragment card on AIPlugin {
      config {
        ...AiGroups_groups
      }
    }
    ${AiGroups.query.groups}
    fragment ascetGroupsFragment on AIPlugin {
      config {
        groups {
          ...AiGroupSettings_group
        }
      }
    }
  `;

  static mutation = {
    addAiGroup: gql`
      mutation ADD_AI_GROUP($cardId: String!, $title: String!) {
        addAiGroup(cardId: $cardId, title: $title) {
          id
          title
          ...AiIntentsList_intents
        }
      }
      ${AiIntentsList.query}
    `,
    updateAiGroupTitle: gql`
      mutation UPDATE_AI_GROUP_TITLE(
        $cardId: String!
        $groupId: String!
        $title: String!
      ) {
        updateAiGroupTitle(cardId: $cardId, groupId: $groupId, title: $title)
      }
    `,
    removeAiGroup: gql`
      mutation REMOVE_AI_GROUP($cardId: String!, $groupId: String!) {
        removeAiGroup(cardId: $cardId, groupId: $groupId)
      }
    `,
    cloneAiGroup: gql`
      mutation CLONE_AI_GROUP(
        $cardId: String!
        $groupId: String!
        $botId: String!
      ) {
        cloneAiGroupToBot(cardId: $cardId, groupId: $groupId, botId: $botId) {
          id
          title
          ...AiIntentsList_intents
        }
      }
      ${AiIntentsList.query}
    `,
  };

  handleAddGroupClick = () => {
    sendEvent({
      category: 'ai group',
      action: 'add',
    });
    const { groups, cardId } = this.props;

    const title = getIncrementName(
      window.i18next.t('AiGroups-string--933-new-group'),
      groups.map(titleProp),
    );
    const activeIndex = groups.length;
    const tmpIntentId = nanoid();
    client.mutate({
      mutation: AiGroups.mutation.addAiGroup,
      variables: {
        title,
        cardId,
      },
      optimisticResponse: {
        addAiGroup: {
          title,
          __typename: 'AiGroup',
          id: title,
          intents: [
            {
              __typename: 'AiIntent',
              id: tmpIntentId,
              intent_id: tmpIntentId,
              filter_type: AiIntentFilterType.default,
              lines: [],
              action: {
                __typename: 'AiIntentAction',
                random: false,
                items: [],
              },
              ig_action: {
                __typename: 'AiIntentAction',
                random: false,
                items: [],
              },
              wa_action: {
                __typename: 'AiIntentAction',
                random: false,
                items: [],
              },
            },
          ],
        },
      },
      update: (store, { data }) => {
        if (data && data.addAiGroup) {
          const cardFragment: AIPlugin | null = store.readFragment({
            id: cardId,
            fragmentName: 'card',
            fragment: this.cardFragment,
          });

          if (cardFragment) {
            const {
              config: { groups },
            } = cardFragment;
            cardFragment.config.groups = [
              ...groups,
              data.addAiGroup,
            ] as Group[];

            store.writeFragment({
              id: cardId,
              fragmentName: 'card',
              fragment: this.cardFragment,
              data: cardFragment,
            });
          }

          this.setState({ activeGroupIndex: activeIndex });
        }
      },
    });
  };

  handleCloneGroup = (variables: { botId: string }) => {
    sendEvent({
      category: 'ai group',
      action: 'clone',
    });

    this.handleDismiss();
    const { activeGroupIndex } = this.state;
    const { groups, cardId, currentBotId } = this.props;
    const activeIndex = groups.length;
    const currentGroup = groups[activeGroupIndex];
    const title = getIncrementName(
      `${currentGroup.title} Copy`,
      groups.map(titleProp),
    );
    client.mutate({
      mutation: AiGroups.mutation.cloneAiGroup,
      variables: {
        ...variables,
        cardId,
        groupId: currentGroup.id,
      },
      optimisticResponse: {
        cloneAiGroupToBot: {
          title,
          __typename: 'AiGroup',
          id: title,
          intents: [...currentGroup.intents],
        },
      },
      update: (store, { data }) => {
        if (variables.botId !== currentBotId) {
          return;
        }
        if (data && data.cloneAiGroupToBot) {
          const cardFragment: AIPlugin | null = store.readFragment({
            id: cardId,
            fragmentName: 'card',
            fragment: this.cardFragment,
          });
          if (cardFragment) {
            const {
              config: { groups },
            } = cardFragment;
            cardFragment.config.groups = [
              ...groups,
              data.cloneAiGroupToBot,
            ] as Group[];

            store.writeFragment({
              id: cardId,
              fragmentName: 'card',
              fragment: this.cardFragment,
              data: cardFragment,
            });
          }
          this.setState({ activeGroupIndex: activeIndex });
        }
      },
    });
  };

  handleRenameGroup = (groupIndex: number, newName: string) => {
    sendEvent({
      category: 'ai group',
      action: 'rename',
    });

    const { groups } = this.props;
    const group = groups[groupIndex];

    if (group) {
      client.writeFragment({
        id: group.id,
        fragment: gql`
          fragment group on AiGroup {
            title
          }
        `,
        data: {
          __typename: 'AiGroup',
          title: newName,
        },
      });
    }

    this.mutateGroupTitle(group.id, newName);
  };

  handleGroupDeleteClick = (groupIndex: number) => {
    sendEvent({
      category: 'ai group',
      action: 'delete',
    });

    const { groups } = this.props;
    const group = groups[groupIndex];

    if (group && group.intents && group.intents.length > 0) {
      this.setState({
        showPopUp: true,
        actionType: 'delete',
      });
    } else {
      this.handleGoDeleteClickInPopup(group.id);
    }
  };

  handleGoDeleteClickInPopup = (groupId: string) => {
    sendEvent({
      category: 'ai group',
      action: 'delete popup',
    });

    const { cardId, groups } = this.props;
    const currentIndex = groups.findIndex(propEq('id', groupId));
    const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;

    this.setState({
      activeGroupIndex: newIndex,
      showPopUp: false,
      actionType: '',
    });

    client.mutate({
      mutation: AiGroups.mutation.removeAiGroup,
      variables: {
        groupId,
        cardId,
      },
      optimisticResponse: {
        removeAiGroup: true,
      },
      update: (store, { data }) => {
        if (data && data.removeAiGroup) {
          const cardFragment: AIPlugin | null = store.readFragment({
            id: cardId,
            fragmentName: 'ascetGroupsFragment',
            fragment: this.cardFragment,
          });

          if (cardFragment) {
            const {
              config: {
                groups: [...newGroups],
              },
            } = cardFragment;
            const groupIndex = findIndexById(groupId, newGroups as Group[]);
            newGroups.splice(groupIndex, 1);
            cardFragment.config.groups = newGroups;

            store.writeFragment({
              id: cardId,
              fragmentName: 'ascetGroupsFragment',
              fragment: this.cardFragment,
              data: cardFragment,
            });
          }
        }
      },
    });
  };

  handleDismiss = () => this.setState({ showPopUp: false, actionType: '' });

  validate = (newName: string, tabIndex: number) => {
    if (newName === '') {
      return {
        valid: false,
        errorMessage: window.i18next.t(
          'AiGroups-string--895-group-name-can-not-be-empty',
        ),
      };
    }

    const tabId: string = pathOr('', ['props', 'groups', tabIndex, 'id'], this);
    const groups = this.props.groups.filter(propEq('title', newName));

    if (groups.length > 1 || pathOr(tabId, [0, 'id'], groups) !== tabId) {
      return {
        valid: false,
        errorMessage: window.i18next.t(
          'AiGroups-string--801-group-with-this-name-already-exists',
        ),
      };
    }

    return {
      valid: true,
      errorMessage: undefined,
    };
  };

  render() {
    const { activeGroupIndex } = this.state;
    const { groups, cardId, blocksGroups, currentBotId, intentsFilterTerm } =
      this.props;
    const currentGroup = groups[activeGroupIndex];
    const activeGroupId = currentGroup?.id;

    const filteredIntents =
      currentGroup &&
      currentGroup.intents.filter(intentsFilter(intentsFilterTerm));

    const groupsWithFade: GroupExtended[] =
      intentsFilterTerm !== ''
        ? groups.map((group) => ({
            ...group,
            faded: !group.intents.some(intentsFilter(intentsFilterTerm)),
          }))
        : groups;

    return (
      <React.Fragment>
        <div className={`${css.lineLayout} ${css.tabsBoxLayout}`}>
          <RoleConsumer domain="ai" can={Permission.EDIT}>
            {({ allowed }) => (
              <TabsSet
                tabs={groupsWithFade}
                activeTabIndex={activeGroupIndex}
                createNewTab={this.handleAddGroupClick}
                renameTab={this.handleRenameGroup}
                removeTab={
                  groupsWithFade.length > 1
                    ? this.handleGroupDeleteClick
                    : undefined
                }
                cloneTab={() =>
                  this.setState({ showPopUp: true, actionType: 'clone' })
                }
                validate={(name, tabIndex = -1) =>
                  this.validate(name, tabIndex).valid
                }
                getErrorMessage={(name, tabIndex = -1) =>
                  this.validate(name, tabIndex).errorMessage
                }
                onTabClick={(tabIndex) => {
                  this.setState({
                    activeGroupIndex: tabIndex,
                  });
                }}
                allowCreateNewTabs={allowed}
                allowEditTabs={allowed}
              />
            )}
          </RoleConsumer>
        </div>
        {currentGroup && filteredIntents && (
          <AiIntentsList
            cardId={cardId}
            groupId={activeGroupId}
            intents={filteredIntents}
            blocksGroups={blocksGroups}
            groups={groups}
            beforeAddCallback={this.props.onBeforeAddIntent}
            renderPlaceholder={() =>
              intentsFilterTerm === '' ? null : <NoIntentsPlaceholder />
            }
            currentBotId={currentBotId}
          />
        )}

        {this.state.showPopUp && (
          <Modal
            onDismiss={() =>
              this.setState({
                showPopUp: false,
              })
            }
          >
            {this.state.actionType === 'clone' && (
              <GroupActionsDialog
                onSubmit={this.handleCloneGroup}
                renderHeading={() =>
                  window.i18next.t('AiGroups-string-2446-clone-group')
                }
                renderActionText={() =>
                  window.i18next.t('AiGroups-string-6520-clone')
                }
                currentBotId={this.props.currentBotId}
                onDismiss={this.handleDismiss}
              />
            )}
            {this.state.actionType === 'delete' && (
              <ConfirmDeleteGroupDialog
                onDismiss={this.handleDismiss}
                onConfirmDelete={this.handleGoDeleteClickInPopup}
                renderHeading={() =>
                  window.i18next.t('AiGroups-string-1194-confirm-deletion')
                }
                renderActionText={() =>
                  window.i18next.t('AiGroups-string-2043-delete')
                }
                renderDescription={() =>
                  window.i18next.t(
                    'AiGroups-string--489-please-enter-delete-to-delete-this-group-of-ai-rules-this-is-needed-to-help-you-avoid-accidental-purging-of-the-whole-group-of-ai-rules',
                  )
                }
                confirmationText={window.i18next.t(
                  'modernComponents.Aside.GroupContextMenu.deleteConfirmationText',
                )}
                group={findById(activeGroupId, groups)}
              />
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
