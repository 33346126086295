import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { range } from 'ramda';

import cn from 'classnames';

import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { Box } from '@ui/Box';

import { Spacer } from '@ui/Spacer';
import * as css from './AiSetupPage.module.less';
import * as loaderCss from './AiSetupPageLoader.css';

export function AiSetupPageLoader() {
  const { t } = useSafeTranslation();
  const { isAutomateEnabled } = useAutomateEnabled();
  return (
    <div className={cn(css.layout, css.loaderLayout)}>
      <div className={css.lineLayout}>
        <div className={css.infoText}>
          <div className={css.infoText}>
            <div className={css.textHeader}>
              <Type as="h4" weight="bold" size="15px_DEPRECATED">
                {t('pages.Ai.title')}
              </Type>
            </div>
            <div className={css.text}>
              <span>
                <Type color="greyDark" size="15px_DEPRECATED">
                  {isAutomateEnabled
                    ? t('pages.Ai.descriptionBlock')
                    : t('pages.Ai.descriptionFlow')}
                </Type>
                <div
                  className={cn(
                    css.loadingControlPlaceholder,
                    loaderCss.languageLoader,
                  )}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <Flex justifyContent="space-between">
        <Box
          className={cn(
            loaderCss.defaultIntentLoader,
            css.loadingIntentPlaceholder,
          )}
        />
        <Box
          className={cn(
            loaderCss.defaultIntentLoader,
            css.loadingIntentPlaceholder,
          )}
        />
      </Flex>
      <Spacer factor={24} />
      <div
        className={cn(
          css.lineLayout,
          css.tabsBoxLayout,
          loaderCss.tabsBoxLayout,
        )}
      >
        <div
          className={cn(css.loadingControlPlaceholder, loaderCss.tabLoader)}
        />
        <div
          className={cn(css.loadingControlPlaceholder, loaderCss.tabLoader)}
        />
        <div
          className={cn(css.loadingControlPlaceholder, loaderCss.tabLoader)}
        />
        <div
          className={cn(css.loadingControlPlaceholder, loaderCss.tabLoader)}
        />
        <div
          className={cn(css.loadingControlPlaceholder, loaderCss.searchLoader)}
        />
      </div>
      <div className={css['intent-box_layout']}>
        {range(0, 4).map((i) => (
          <Box
            key={i}
            className={cn(css.intent_layout, css.loadingIntentPlaceholder)}
          />
        ))}
      </div>
    </div>
  );
}
