import React from 'react';
import * as css from './AiSetupPage.module.less';

export const NoIntentsPlaceholder: React.SFC<{}> = () => (
  <div className={css.intentPlaceholder}>
    <div className={css.intentPlaceholderHeader}>
      {window.i18next.t('Placeholder-JSXText-1551-nothing-to-say-here')}
    </div>
    <div className={css.intentPlaceholderText}>
      <span>
        {window.i18next.t(
          'Placeholder-JSXText-4297-try-searching-for-other-keywords-or',
        )}
      </span>
      <br />
      <span>
        {window.i18next.t(
          'Placeholder-JSXText-1228-look-into-another-rule-group',
        )}
      </span>
    </div>
  </div>
);
