import memoize from 'lodash-es/memoize';
import i18next from 'i18next';
import { AiIntentFilterType } from '@globals';

export const getAiRuleTypeTitles = memoize(
  (): Record<AiIntentFilterType, string> => ({
    [AiIntentFilterType.default]: i18next.t('pages.Ai.is'),
    [AiIntentFilterType.contains]: i18next.t('pages.Ai.contains'),
    [AiIntentFilterType.case_insensitive_match]: i18next.t('pages.Ai.matches'),
  }),
);

export const getAiRuleTypeTooltips = memoize(
  (): Record<AiIntentFilterType, string> => ({
    [AiIntentFilterType.default]: i18next.t('pages.Ai.isTooltip'),
    [AiIntentFilterType.contains]: i18next.t('pages.Ai.containsTooltip'),
    [AiIntentFilterType.case_insensitive_match]: i18next.t(
      'pages.Ai.matchesTooltip',
    ),
  }),
);
