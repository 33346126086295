import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { sendEvent } from '@utils/Analytics';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';

import { ButtonUnstyled } from '@ui/Button';
import { Input, InputColorWay } from '@ui/Input';
import { Icon } from '@ui/Icon';

import { intentsFilter } from './helpers';

import { GroupExtended } from '../types';
import { AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups as Group } from '../@types/AI_SETUP_QUERY';

interface AiSearchFieldProps {
  groups: Group[];
  intentsFilterTerm: string;
  onIntentsFitlerTermChange(value: string): void;
}
export const AiSearchField: React.FC<AiSearchFieldProps> = ({
  groups,
  intentsFilterTerm,
  onIntentsFitlerTermChange,
}) => {
  const { t } = useSafeTranslation();
  const { isAutomateEnabled } = useAutomateEnabled();
  const groupsWithFade: GroupExtended[] =
    intentsFilterTerm !== ''
      ? groups.map((group) => ({
          ...group,
          faded: !group.intents.some(intentsFilter(intentsFilterTerm)),
        }))
      : groups;

  const validSearch =
    intentsFilterTerm === '' || groupsWithFade.some((group) => !group.faded);

  return (
    <Input
      data-testid="ai__search-input"
      value={intentsFilterTerm}
      onChange={(event) => onIntentsFitlerTermChange(event.currentTarget.value)}
      onBlur={() => {
        sendEvent({
          category: 'ai search',
          action: 'click',
        });
      }}
      renderIcon={() => <Icon icon="search" color="grey" />}
      renderIconEnd={() =>
        intentsFilterTerm.length ? (
          <ButtonUnstyled
            onClick={() => {
              onIntentsFitlerTermChange('');
            }}
          >
            <Icon icon="close" color="black" />
          </ButtonUnstyled>
        ) : null
      }
      placeholder={
        isAutomateEnabled
          ? t('pages.Ai.SearchField.placeholderBlocks')
          : t('pages.Ai.SearchField.placeholderFlows')
      }
      colorWay={InputColorWay.dark}
      className="test-search-intent-input"
      error={!validSearch}
    />
  );
};
