import React from 'react';
import gql from 'graphql-tag';
import nanoid from 'nanoid';
import cn from 'classnames';

import { sendEvent } from '@utils/Analytics';
import { RoleConsumer } from '@utils/Roles';

import { Button, ButtonSize, ButtonIntent } from '@ui/_deprecated/Button';
import { ReactComponent as AddIcon } from '@ui/_deprecated/Icon/icons/ic_add_small.svg';
import { removeTypename } from '@utils/GQL/utils';

import client from '../../common/services/ApolloService';
import { Permission } from '../../common/services/RoleService';
import { AiIntent } from './AiIntent';

import { BlockWithPermissions } from './types';
import {
  AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups,
  AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups_intents,
} from './@types/AI_SETUP_QUERY';

import * as css from './AiSetupPage.module.less';
import { AiIntentFilterType } from '@globals';

interface AiIntentsListProps {
  intents: AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups_intents[];
  cardId: string;
  groupId: string;
  blocksGroups: BlockWithPermissions[];
  groups: AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups[];
  beforeAddCallback?: () => void;
  renderPlaceholder?: () => React.ReactNode;
  onMoveIntentToGroup?: (groupId: string) => void;
  currentBotId: string;
}

interface AiIntentsListState {
  pinButton: boolean;
  newItemAdded: boolean;
}

export class AiIntentsList extends React.Component<
  AiIntentsListProps,
  AiIntentsListState
> {
  state = {
    pinButton: false,
    newItemAdded: false,
  };

  addButtonWrapper: HTMLDivElement | null = null;

  componentDidMount() {
    this.testShowInnerAddButton();
  }

  componentDidUpdate(prevProps: AiIntentsListProps) {
    if (prevProps.intents.length !== this.props.intents.length) {
      this.testShowInnerAddButton();
    }
  }

  static mutation = {
    addAiIntent: gql`
      mutation ADD_AI_INTENT(
        $cardId: String!
        $groupId: String!
        $intent: AiIntentInput!
      ) {
        addAiIntent(cardId: $cardId, groupId: $groupId, intent: $intent) {
          __typename
          id
          intent_id
          ...AiIntent_intent
        }
      }
      ${AiIntent.query.intent}
    `,
  };

  static query = gql`
    fragment AiIntentsList_intents on AiGroup {
      intents {
        id
        intent_id
        ...AiIntent_intent
      }
    }
    ${AiIntent.query.intent}
  `;

  getAddButtonWrapper = (wrapper: HTMLDivElement) => {
    this.addButtonWrapper = wrapper;
  };

  handleAddIntentClick = () => {
    sendEvent({
      category: 'ai rule',
      action: 'add',
    });

    if (this.props.beforeAddCallback) {
      this.props.beforeAddCallback();
    }

    const tmpIntentId = nanoid();
    this.setState({ newItemAdded: true });

    const newIntent: AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups_intents =
      {
        __typename: 'AiIntent',
        id: tmpIntentId,
        intent_id: tmpIntentId,
        filter_type: AiIntentFilterType.default,
        lines: [],
        name: null,
        action: {
          __typename: 'AiIntentAction',
          random: false,
          items: [],
        },
        ig_action: {
          __typename: 'AiIntentAction',
          random: false,
          items: [],
        },
        wa_action: {
          __typename: 'AiIntentAction',
          random: false,
          items: [],
        },
      };

    client.mutate({
      mutation: AiIntentsList.mutation.addAiIntent,
      variables: {
        cardId: this.props.cardId,
        groupId: this.props.groupId,
        intent: removeTypename(newIntent),
      },
      optimisticResponse: {
        addAiIntent: newIntent,
      },
      update: (store, { data }) => {
        if (data && data.addAiIntent) {
          const resultNewIntent = data.addAiIntent;

          const intentsFragment: AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups | null =
            store.readFragment({
              id: this.props.groupId,
              fragmentName: 'AiIntentsList_intents',
              fragment: AiIntentsList.query,
            });

          if (intentsFragment) {
            const { intents } = intentsFragment;
            intentsFragment.intents = [resultNewIntent, ...intents];

            store.writeFragment({
              id: this.props.groupId,
              fragmentName: 'AiIntentsList_intents',
              fragment: AiIntentsList.query,
              data: intentsFragment,
            });
          }
        }
        setTimeout(
          () =>
            this.setState({
              newItemAdded: false,
            }),
          1000,
        );
      },
    });
  };

  testShowInnerAddButton = () => {
    if (!this.addButtonWrapper) {
      return;
    }
    const boxRect = this.addButtonWrapper.getBoundingClientRect();

    this.setState({
      pinButton: boxRect.bottom > window.innerHeight,
    });
  };

  renderAddButton = () => {
    return (
      <RoleConsumer domain="ai" can={Permission.EDIT}>
        <Button
          data-testid="ai__rule-add-button"
          renderIcon={() => <AddIcon className={css.iconWhite} />}
          intent={ButtonIntent.primary}
          size={ButtonSize.m}
          onClick={this.handleAddIntentClick}
          className="test-add-intent-button"
        >
          {window.i18next.t('AiIntentsList-JSXText-1969-add-ai-rule')}
        </Button>
      </RoleConsumer>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.pinButton ? (
          <div className={css.addButtonWrapper}>
            <div
              className={cn({
                [css.lineLayout]: true,
                [css.pinnedButtonPad]: this.state.pinButton,
              })}
            >
              {this.renderAddButton()}
            </div>
          </div>
        ) : null}
        {this.props.intents.length > 0 ? (
          <div className={css.intentBoxLayout}>
            {this.props.intents.map((intent, index) => (
              <AiIntent
                key={intent.id}
                autofocus={this.state.newItemAdded && index === 0}
                intent={intent}
                cardId={this.props.cardId}
                groupId={this.props.groupId}
                blocksGroups={this.props.blocksGroups}
                groups={this.props.groups}
                onMoveIntentToGroup={this.props.onMoveIntentToGroup}
                currentBotId={this.props.currentBotId}
              />
            ))}
          </div>
        ) : (
          this.props.renderPlaceholder && this.props.renderPlaceholder()
        )}
        <div
          ref={this.getAddButtonWrapper}
          className={cn(css.lineLayout, css.listBottom)}
        >
          {this.state.pinButton ? null : this.renderAddButton()}
        </div>
      </React.Fragment>
    );
  }
}
