import {
  DragSource,
  DragSourceSpec,
  DropTarget,
  DropTargetSpec,
} from 'react-dnd';
import gql from 'graphql-tag';
import client from '../../common/services/ApolloService';
import { GroupTabsProps } from './AiGroups';
import { AiIntentProps } from './AiIntent';
import { AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups as Group } from './@types/AI_SETUP_QUERY';

type identity = <T>(t: T) => T;
// identify both target and source by string tag;
const Types = {
  moveToGroup: 'MOVE_TO_GROUP',
};

export interface GroupDropItem {
  id: string;
}

export interface IntentDragItem {
  intentId: string;
  groupId: string;
  cardId: string;
}

export const aiGroupsReorderFragment = gql`
  fragment aiGroupsReorderFragment on AiGroup {
    id
    intents {
      id
    }
  }
`;

const mutation = gql`
  mutation MOVE_INTENT_TO_GROUP(
    $cardId: String!
    $fromGroupId: String!
    $toGroupId: String!
    $intentId: String
  ) {
    moveIntentToGroup(
      cardId: $cardId
      fromGroupId: $fromGroupId
      toGroupId: $toGroupId
      intentId: $intentId
    )
  }
`;

const reorder = async (
  fromGroupId: string,
  intentId: string,
  toGroupId: string,
  cardId: string,
) => {
  // read store fragment;
  const fragment = aiGroupsReorderFragment;
  try {
    const from = client.readFragment({
      fragment,
      id: fromGroupId,
    }) as Group;
    const to = client.readFragment({
      fragment,
      id: toGroupId,
    }) as Group;

    if (from) {
      const index = from.intents.findIndex((intent) =>
        Boolean(intent && intent.id === intentId),
      );
      if (index !== -1) {
        const intentToMove = from.intents.splice(index, 1);
        to.intents.unshift(...intentToMove);
        client.writeFragment({
          fragment,
          id: fromGroupId,
          data: from,
        });

        client.writeFragment({
          fragment,
          id: toGroupId,
          data: to,
        });

        await client.mutate({
          mutation,
          variables: {
            cardId,
            toGroupId,
            fromGroupId,
            intentId,
          },
        });
      }
    }
  } catch (e) {
    console.error('error during intents reordering', e);
  }
};

const groupSource: DragSourceSpec<AiIntentProps, IntentDragItem> = {
  // props to dragbble;
  beginDrag: (prop) => {
    const dragedItem = {
      intentId: prop.intent.id,
      groupId: prop.groupId,
      cardId: prop.cardId,
    };
    return dragedItem;
  },
  // do action;
  endDrag(_props, monitor) {
    if (!monitor.didDrop()) {
      return;
    }
    const dropResult = monitor.getDropResult() as GroupDropItem;
    const itemDragged = monitor.getItem(); // item where we drop;
    reorder(
      itemDragged.groupId,
      itemDragged.intentId,
      dropResult.id,
      itemDragged.cardId,
    );
  },
};

const dropSpec: DropTargetSpec<GroupTabsProps> = {
  drop: (props) => {
    // you can return there drop result; object represented on what somthing was droped;
    return { id: props.tab.id };
  },
  canDrop: (props, monitor) => {
    // do not drop on self;
    const item: IntentDragItem = monitor.getItem();
    return props.tab.id !== item.groupId;
  },
};

export const DropDecorator = DropTarget(
  Types.moveToGroup,
  dropSpec,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
) as identity;

export const DragDecorator = DragSource(
  Types.moveToGroup,
  groupSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
) as identity;
