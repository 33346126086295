import gql from 'graphql-tag';
import { AI_DEFAULT_ANSWER_FRAGMENT } from '@utils/Data/Ai/PredefinedIntents/useAiDefaultAnswer/queries';
import { LanguageSelector } from './LanguageSelector';
import { AiGroups } from './AiGroups';
import { aiGroupsReorderFragment } from './MoveToGroup';
import { BlockSelectorQuery } from './BlocksSelector';

export const AI_SETUP_QUERY = gql`
  query AI_SETUP_QUERY($botId: String!) {
    __typename
    bot(id: $botId) {
      id
      title
      aiBlock {
        id
        cards {
          ...LanguageSelector_cardId
          ...AiGroup_cardId
          ... on AIPlugin {
            config {
              ...LanguageSelector_currentLanguage
              ...AiGroups_groups
              groups {
                ...aiGroupsReorderFragment
              }
            }
          }
        }
      }
      ...LanguageSelector_languages
      ...aiDefaultAnswerFragment
    }
    bots {
      ...AiGroups_bots
    }
  }
  ${LanguageSelector.query.currentLanguage}
  ${LanguageSelector.query.languages}
  ${LanguageSelector.query.cardId}
  ${AiGroups.query.groups}
  ${AiGroups.query.cardId}

  ${AiGroups.query.bots}
  ${aiGroupsReorderFragment}
  ${AI_DEFAULT_ANSWER_FRAGMENT}
`;

export const BLOCK_GROUPS_QUERY = gql`
  query BlockGroupsQuery($botId: String!) {
    bot(id: $botId) {
      id
      ...BlocksSelector_blocksGroups
    }
  }
  ${BlockSelectorQuery.blocksGroups}
`;
