import { compose, contains, curry, isEmpty, propOr, toLower } from 'ramda';
import { AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups_intents as GroupIntents } from '../@types/AI_SETUP_QUERY';

const isIntentContainTerm = (intentsFilterTerm: string) =>
  compose(contains(toLower(intentsFilterTerm || '')), toLower);
const titleProp = propOr('', 'title');
export const intentsFilter = curry(
  (intentsFilterTermNullable: string | null, intent: GroupIntents) => {
    const nonEmptyLines = intent.lines.filter(Boolean);
    const intentsFilterTerm = intentsFilterTermNullable ?? '';
    const containTerm = isIntentContainTerm(intentsFilterTerm);
    const isIntentLinesContainTerm = nonEmptyLines.some(containTerm);
    const isIntentsFilterTermEmpty = isEmpty(intentsFilterTerm);
    const isIntentActionItemsContainTerm = [
      ...intent.action.items,
      ...intent.ig_action.items,
      ...intent.wa_action.items,
    ].some(
      (item) =>
        !!item &&
        ((item.text && containTerm(item.text)) ||
          !!(item.blocks && item.blocks.find(compose(containTerm, titleProp)))),
    );

    return (
      isIntentsFilterTermEmpty ||
      isIntentLinesContainTerm ||
      isIntentActionItemsContainTerm
    );
  },
);
