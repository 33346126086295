import { useBlocksSelectorData } from '@components/BlocksSelector2';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { useBlocksGroupsWithPermissions } from '@utils/Data/Blocks/useBlocksGroupsWithPermissions';
import { useCurrentBotId } from '@utils/Routing';
import React, { useMemo } from 'react';
import { Icon } from '@ui/Icon';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Platform } from '@globals';
import { BlockGroupsQuery_bot_blocksGroups_blocks } from '../../../@types/BlockGroupsQuery';
import { BlocksSelector } from '../../../BlocksSelector';
import { BlockWithPermissions } from '../../../types';
import * as css from './AiDefaultAnswerItem.css';
import { Translation } from '@translations';

interface AiDefaultAnswerProps {
  platformId: Platform;
  selectedTargets: BlockGroupsQuery_bot_blocksGroups_blocks[];
  onChange: (
    selectedTargets: BlockGroupsQuery_bot_blocksGroups_blocks[],
  ) => void;
  intentsFilterTerm: string;
  showNote?: boolean;
}

interface PlatformViewConfig {
  icon: JSX.Element;
  titleKey: Translation;
}

const PLATFORMS: Record<Platform, PlatformViewConfig> = {
  [Platform.facebook]: {
    icon: <Icon icon="messengerLogo" color="blueDark10" size="12px" />,
    titleKey: 'pages.Ai.DefaultAnswer.Messenger',
  },
  [Platform.instagram]: {
    icon: <Icon icon="directLogo" color="fuchsia" size="12px" />,
    titleKey: 'pages.Ai.DefaultAnswer.Instagram',
  },
  [Platform.whatsapp]: {
    icon: <Icon icon="whatsappLogo" size="12px" />,
    titleKey: 'pages.Ai.DefaultAnswer.Whatsapp',
  },
};

export const AiDefaultAnswerItem: React.FC<AiDefaultAnswerProps> = ({
  platformId,
  selectedTargets,
  intentsFilterTerm,
  showNote,
  onChange,
}) => {
  const platform = PLATFORMS[platformId];

  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const { flowsGroups } = useBlocksSelectorData();
  const { blocksGroupsWithPermissions } = useBlocksGroupsWithPermissions();
  const { isAutomateEnabled } = useAutomateEnabled();

  const needShowBlocks = platformId === Platform.facebook && isAutomateEnabled;

  const intentsFilterTermClean = useMemo(
    () => intentsFilterTerm.trim().toLowerCase(),
    [intentsFilterTerm],
  );

  const isNotFound = useMemo(
    () =>
      intentsFilterTermClean &&
      !selectedTargets.some(({ title }) =>
        title.trim().toLowerCase().includes(intentsFilterTermClean),
      ),
    [intentsFilterTermClean, selectedTargets],
  );

  const placeholder = useMemo(
    () =>
      `${t('pages.Ai.DefaultAnswer.ChooseFlow')}${
        needShowBlocks ? ` ${t('pages.Ai.DefaultAnswer.orBlock')}` : ''
      }`,
    [t, needShowBlocks],
  );

  const blockGroupsCurrent = useMemo(
    () =>
      needShowBlocks
        ? (blocksGroupsWithPermissions as BlockWithPermissions[]).filter(
            (group) => !group.hidden,
          )
        : [],
    [blocksGroupsWithPermissions, needShowBlocks],
  );

  if (!botId) {
    return null;
  }

  return (
    <div
      className={css.col}
      style={{
        width: showNote ? 976 : undefined,
      }}
    >
      <div className={css.box}>
        <Flex alignItems="center">
          <div className={css.icon}>{platform.icon}</div>
          <Spacer horizontalFactor={2} factor={1} />
          <Type weight="semibold" size="15px_DEPRECATED">
            {t(platform.titleKey)}
          </Type>
        </Flex>
        <Spacer factor={2} />
        <Flex alignItems="center">
          {showNote && (
            <div>
              <Type size="15px_DEPRECATED">
                {t('pages.Ai.DefaultAnswer.description')}
              </Type>
            </div>
          )}
          {!isNotFound && (
            <div className={css.blockSelectorBox}>
              <BlocksSelector
                data-testid={`ai_default-ai-answer_${platformId}`}
                blocksGroups={blockGroupsCurrent}
                flowsGroups={flowsGroups}
                blocksSelected={selectedTargets}
                onChange={(blocksSelected) => {
                  sendEvent({
                    category: 'ai default answer',
                    action: 'select block',
                    propertyBag: {
                      platform: platformId,
                      blocksId: blocksSelected,
                    },
                  });
                  onChange(blocksSelected);
                }}
                currentBotId={botId}
                hideTips
                placeholder={placeholder}
                platform={platformId}
              />
            </div>
          )}
          {isNotFound && (
            <Flex alignItems="center" className={css.nothingFoundHere}>
              <Type color="greyDark" size="15px_DEPRECATED">
                {t('pages.Ai.SearchField.nothingFoundHere')}
              </Type>
            </Flex>
          )}
        </Flex>
      </div>
      {!selectedTargets.length && (
        <>
          <Spacer factor={2} />
          <Flex alignItems="flex-start">
            <Icon icon="info" color="red" size="24px" />
            <Spacer horizontalFactor={2} />
            <Type color="red" size="15px_DEPRECATED">
              {t('pages.Ai.DefaultAnswer.error')}
            </Type>
          </Flex>
        </>
      )}
    </div>
  );
};
