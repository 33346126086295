import React from 'react';
import { compose } from 'ramda';

import { Autofocus } from '@ui/Autofocus';
import { Button, ButtonIntent } from '@ui/_deprecated/Button';
import { Dialog, DialogHeading } from '@ui/Dialog';
import {
  FlexDialogContent,
  FormLine,
  SubmitRow,
  CloseButton,
} from '@ui/Dialog/ActionDialogParts';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input/Input';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Suggest } from '@ui/Suggest2';

import { AI_SETUP_QUERY_bot_aiBlock_cards_AIPlugin_config_groups as Group } from './@types/AI_SETUP_QUERY';

import * as css from './AiDialog.css';

export const SuggestGroups = Suggest as new () => Suggest<Group>;

interface ConfirmMoveIntentProps {
  onSubmit: (params: { groupId: string }) => void;
  groups: Group[];
  renderHeading: () => React.ReactNode;
  renderActionText: () => React.ReactNode;
  onDismiss?: () => void;
}

interface ConfirmMoveIntentState {
  selectedGroup?: Group;
  groupId: string;
}

export class ConfirmMoveIntentModal extends React.Component<
  ConfirmMoveIntentProps,
  ConfirmMoveIntentState
> {
  state = {
    selectedGroup: this.props.groups[0],
    groupId: this.props.groups[0].id,
  };

  handleGroupChange = (group: Group) => {
    this.setState({
      selectedGroup: group,
    });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) {
      return;
    }

    this.props.onSubmit({
      groupId: this.state.groupId,
    });
  };

  render() {
    const { groups, onDismiss, renderActionText, renderHeading } = this.props;
    const { selectedGroup, groupId } = this.state;

    return (
      <Dialog>
        <form onSubmit={this.handleSubmit}>
          <FlexDialogContent>
            <div>
              <DialogHeading>
                <Flex alignItems="baseline" justifyContent="space-between">
                  <div style={{ paddingRight: 8 }}>{renderHeading()}</div>
                  {onDismiss ? (
                    <CloseButton
                      aria-label="close"
                      type="button"
                      onClick={onDismiss}
                    />
                  ) : null}
                </Flex>
              </DialogHeading>
              <FormLine>
                <label>
                  <div className={css.formLabel}>
                    {window.i18next.t(
                      'ConfirmMoveIntentModal-JSXText-3127-to-the-group',
                    )}
                  </div>
                  <SimpleCombobox
                    key="ready"
                    initialSelectedItem={selectedGroup}
                    onChange={(item) =>
                      this.setState({ groupId: item ? item.id : '' })
                    }
                    items={groups}
                    renderInput={({
                      getInputProps,
                      openMenu,
                      selectItem,
                      selectInputValue,
                      ref,
                    }) => (
                      <Autofocus
                        shouldFocus
                        render={({ bind }) => (
                          <Input
                            data-testid="ai__move-intent-input"
                            {...getInputProps({
                              ref: (n: HTMLInputElement) => {
                                ref(n);
                                bind.ref(n);
                              },
                              onFocus: compose(openMenu, selectInputValue),
                              onChange: () => {
                                selectItem(null);
                              },
                            })}
                            name="groupId"
                            placeholder={window.i18next.t(
                              'ConfirmMoveIntentModal-string-1605-enter-group-name',
                            )}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </label>
              </FormLine>
            </div>
            <SubmitRow>
              <Button
                data-testid="ai__move-intent-button"
                type="submit"
                disabled={!groupId}
                intent={ButtonIntent.primary}
              >
                {renderActionText()}
              </Button>
            </SubmitRow>
          </FlexDialogContent>
        </form>
      </Dialog>
    );
  }
}
