import React from 'react';
import * as ng from 'angular';
import { angular2react } from 'angular2react';
import { lazyInjector } from '@utils/angularLazyInjector';
import { useReadyToRender } from '@utils/AngularRouting';
import { BotTabAngular } from '@components/BotTab';
import { DisableIfPageNotConnected } from '../BotPage/HomeTab/components/DisableIfPageNotConnected';
import { HomeTabInternalPage } from '../GrowPage/HomeTabInternalPage';
import controller from '../../components/grow/controller';
import template from '../../components/grow/grow.html';

const homeTabWrapper = {
  controller,
  template: () => template,
  controllerAs: 'vm',
};

const HomeTabWrapper = angular2react<{}>(
  'growTabWrapper',
  homeTabWrapper,
  lazyInjector.$injector as any,
);

export const ngHomeTabWrapper = ng
  .module('app.pages.growTab', [])
  .component('growTabWrapper', homeTabWrapper).name;

export const AquireUsersFromComments: React.FC = () => {
  const readyToRender = useReadyToRender('common');

  return (
    <HomeTabInternalPage>
      <DisableIfPageNotConnected>
        <BotTabAngular
          tabName="settings"
          rcol
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '0',
          }}
        >
          {readyToRender && <HomeTabWrapper />}
        </BotTabAngular>
      </DisableIfPageNotConnected>
    </HomeTabInternalPage>
  );
};
