import React from 'react';
import cn from 'classnames';
import gql from 'graphql-tag';
import { GetToggleButtonPropsOptions } from 'downshift';
import { find, propEq, prop, curry } from 'ramda';

import { sendEvent } from '@utils/Analytics';

import Dropdown from '@ui/_deprecated/Dropdown/Dropdown';
import { ReactComponent as DropdownIcon } from '@ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import * as css from '@ui/_deprecated/Dropdown/Dropdown.css';

import client from '../../common/services/ApolloService';

import { AI_SETUP_QUERY_bot_languages as Languages } from './@types/AI_SETUP_QUERY';

interface LanguageSelectorProps {
  languageItems: Languages[];
  selectedLanguageCode: string;
  cardId: string;
  className?: string;
  disabled?: boolean;
}

const findBy = curry((prop: string, term: string, items: Languages[]) =>
  find(propEq(prop, term), items),
);
const findByCode = findBy('code');
const findByName = findBy('name');

export class LanguageSelector extends React.Component<
  LanguageSelectorProps,
  {}
> {
  static mutation = gql`
    mutation UPDATE_CARD_LANGUAGE($languageCode: String!, $cardId: String!) {
      updateCardLanguage(languageCode: $languageCode, cardId: $cardId) {
        id
        config {
          lang
        }
      }
    }
  `;

  static query = {
    languages: gql`
      fragment LanguageSelector_languages on Bot {
        languages {
          id
          code
          name
        }
      }
    `,
    currentLanguage: gql`
      fragment LanguageSelector_currentLanguage on AIPluginConfig {
        lang
      }
    `,
    cardId: gql`
      fragment LanguageSelector_cardId on AIPlugin {
        id
      }
    `,
  };

  buttonsFactory = (
    props: GetToggleButtonPropsOptions,
    ref: React.Ref<any>,
  ) => {
    const { languageItems, selectedLanguageCode } = this.props;

    const selectedItem = findByCode(selectedLanguageCode, languageItems);
    const selectedItemName = selectedItem && selectedItem.name;

    return (
      <button
        data-testid="ai__language-selector-button"
        ref={ref}
        className={cn(css.inTextButton, 'test-language-selector-button')}
        {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
      >
        <span className={css.inTextLabel}>
          <span
            data-testid="ai__selected-language-label"
            className={css.labelText}
          >
            {selectedItemName ||
              window.i18next.t(
                'LanguageSelector-string-1438-select-a-language',
              )}
          </span>
          <DropdownIcon />
        </span>
      </button>
    );
  };

  handleLanguageChange = (name: string) => {
    sendEvent({
      category: 'ai language',
      action: 'update',
      propertyBag: {
        language: name,
      },
    });

    const { languageItems, cardId } = this.props;
    const selectedLanguage = findByName(name, languageItems);

    if (selectedLanguage) {
      const { code: languageCode } = selectedLanguage;
      client.mutate({
        mutation: LanguageSelector.mutation,
        variables: {
          cardId,
          languageCode,
        },
        optimisticResponse: {
          updateCardLanguage: {
            __typename: 'AIPlugin',
            id: cardId,
            config: {
              __typename: 'AIPluginConfig',
              lang: languageCode,
            },
          },
        },
      });
    }
  };

  render() {
    const {
      languageItems,
      selectedLanguageCode,
      className,
      disabled,
    } = this.props;

    const selectedItem = findByCode(selectedLanguageCode, languageItems);
    const selectedItemName = selectedItem && selectedItem.name;

    if (disabled) {
      return (
        <span>
          {selectedItemName ||
            window.i18next.t('LanguageSelector-string-1747-not-selected')}
        </span>
      );
    }

    return (
      <Dropdown
        items={languageItems.map(prop('name'))}
        selectedItem={selectedItemName}
        onChange={this.handleLanguageChange}
        fullWidth={false}
        buttonsFactory={this.buttonsFactory}
        placement="bottom-start"
        className={className}
      />
    );
  }
}
