import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { useCurrentBotId } from '@utils/Routing';
import {
  ActionField,
  useAiDefaultAnswer,
} from '@utils/Data/Ai/PredefinedIntents';

import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';

import { AiIntentActionItemType, Platform } from '@globals';

import { AiDefaultAnswerItem } from './components/AiDefaultAnswerItem';

interface AiDefaultAnswerProps {
  intentsFilterTerm: string;
}

export const AiDefaultAnswer: React.FC<AiDefaultAnswerProps> = ({
  intentsFilterTerm,
}) => {
  const { t } = useSafeTranslation();

  const botId = useCurrentBotId() || '';

  const { aiDefaultAnswerIntent, updateAiDefaultIntent } =
    useAiDefaultAnswer(botId);

  return (
    <>
      <Flex alignItems="center">
        <Type weight="semibold" size="15px_DEPRECATED">
          {t('pages.Ai.DefaultAnswer.title')}
        </Type>
        <Spacer factor={1} horizontalFactor={2} />
        <Tooltip2
          placement="top"
          boundariesElement="viewport"
          content={
            <>
              <Type size="12px" color="white">
                {t('pages.Ai.DefaultAnswer.tooltip')}
              </Type>
            </>
          }
          type="small"
          positionFixed
          hoverable
        >
          {(ref, bind) => (
            <Icon icon="info" size="24px" color="grey" ref={ref} {...bind} />
          )}
        </Tooltip2>
      </Flex>
      <Spacer factor={2} />
      <Flex>
        <AiDefaultAnswerItem
          platformId={Platform.facebook}
          intentsFilterTerm={intentsFilterTerm}
          selectedTargets={aiDefaultAnswerIntent?.action.items[0]?.blocks ?? []}
          onChange={(blocks) => {
            updateAiDefaultIntent({
              blocks,
              type: AiIntentActionItemType.block,
              actionField: ActionField.action,
            });
          }}
        />
        <>
          <Spacer factor={1} horizontalFactor={4} />
          <AiDefaultAnswerItem
            platformId={Platform.instagram}
            intentsFilterTerm={intentsFilterTerm}
            selectedTargets={
              aiDefaultAnswerIntent?.ig_action.items[0]?.blocks ?? []
            }
            onChange={(blocks) => {
              updateAiDefaultIntent({
                blocks,
                type: AiIntentActionItemType.block,
                actionField: ActionField.igAction,
              });
            }}
          />
        </>
      </Flex>
    </>
  );
};
